import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import { useMemo } from 'react';

import { IPropertyApi } from '../../../../types/property';
import { IPromotionApi } from '../../../../types/promotion';
import {
  PreviewSubject,
  PreviewSubjectTypes,
} from '../../../../types/brochureEditor';
import { guaranteeArray } from '../../../../helpers/utils/formatters';
import { useTranslation } from 'react-i18next';

export function usePreviewSubject() {
  const { templateId, propertyId, promotionId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('propertiesPage');
  const { data: editedProperty, error: propertyError } = useSWR<IPropertyApi>(
    propertyId ? `/v1/properties/${propertyId}` : null,
    null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const propertyNotFound = Boolean(
    propertyId && propertyError?.response?.status === 404
  );
  if (propertyNotFound) {
    navigate(`/brochure-templates/${templateId}`);
    toast.error(t('Property_not_found') as string, {
      position: 'bottom-center',
      autoClose: 3000,
    });
  }

  const { data: editedPromotion, error: promotionError } =
    useSWR<IPromotionApi>(
      promotionId ? `/v1/promotions/${promotionId}` : null,
      null,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );
  const promotionNotFound = Boolean(
    promotionId && promotionError?.response?.status === 404
  );
  if (promotionNotFound) {
    navigate(`/brochure-templates/${templateId}`);
    toast.error(t('Promotion_not_found') as string, {
      position: 'bottom-center',
      autoClose: 3000,
    });
  }

  const previewProperty: PreviewSubject | null = useMemo(() => {
    return editedProperty
      ? {
          id: editedProperty.id,
          images: Array.isArray(editedProperty.media)
            ? []
            : editedProperty.media.images,
          address: editedProperty.address,
          replacementAddress: editedProperty.replacementAddress,
          useReplacementAddress:
            editedProperty.publishedLocation?.alternativeBrochure || false,
          department: editedProperty.department,
          mainBroker: editedProperty.mainBroker,
          intermediateBroker: editedProperty.intermediateBroker,
          translations: editedProperty.translations,
          reference: editedProperty.reference,
          agencyReference: editedProperty.agencyReference,
          type: PreviewSubjectTypes.PROPERTY,
        }
      : null;
  }, [editedProperty]);

  const previewPromotion: PreviewSubject | null = useMemo(() => {
    return editedPromotion
      ? {
          id: editedPromotion.id,
          images: editedPromotion.images,
          address: editedPromotion.address,
          replacementAddress: null,
          useReplacementAddress: false,
          department: guaranteeArray(editedPromotion.departments)[0] || null,
          mainBroker: editedPromotion.mainBroker,
          intermediateBroker: null,
          translations: editedPromotion.translations,
          reference: editedPromotion.reference,
          agencyReference: null,
          type: PreviewSubjectTypes.PROMOTION,
        }
      : null;
  }, [editedPromotion]);

  let originalSubject: IPropertyApi | IPromotionApi | undefined;
  let hasLoaded = true;
  let type: PreviewSubjectTypes | null = null;
  let previewSubject: PreviewSubject | null = null;
  if (propertyId) {
    type = PreviewSubjectTypes.PROPERTY;
    hasLoaded = !!editedProperty;
    originalSubject = editedProperty;
    previewSubject = previewProperty;
  } else if (promotionId) {
    type = PreviewSubjectTypes.PROMOTION;
    hasLoaded = !!editedPromotion;
    originalSubject = editedPromotion;
    previewSubject = previewPromotion;
  }

  return useMemo(() => {
    return {
      previewSubject,
      originalSubject,
      hasLoaded,
      type,
    };
  }, [previewSubject, originalSubject, hasLoaded, type]);
}
