import Modal from '../../../../components/modal';
import WithModal from '../../../../components/modal/widthModal';
import { IContactSearch } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import { Button } from '@mui/material';
import EditIcon from '../../../../features/icons/editIcon';
import { IExtendedContactSearch } from '../forms/matching-search-form';
import { formatPrice } from '../../../../helpers/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  item: IExtendedContactSearch | null;
  onClose: () => void;
  contactId: string | number;
};

const preferences = [
  {
    keyname: 'balcony',
    label: 'Balcony',
  },
  {
    keyname: 'garage',
    label: 'Garage',
  },
  {
    keyname: 'seaView',
    label: 'Sea view',
  },
  {
    keyname: 'lakeView',
    label: 'Lake view',
  },
  {
    keyname: 'pool',
    label: 'Pool',
  },
  {
    keyname: 'lift',
    label: 'Lift',
  },
  {
    keyname: 'parking',
    label: 'Parking',
  },
  {
    keyname: 'mountainView',
    label: 'Mountain view',
  },
  {
    keyname: 'terrace',
    label: 'Terrace',
  },
];

interface ExtendItem extends IContactSearch {
  [index: string]: any;
}

const getItems = (item: ExtendItem) => {
  const arr: string[] = [];

  preferences.forEach((el) => {
    if (item && (item?.[el.keyname] as string)) {
      arr.push(el.label);
    }
  });

  return arr;
};

const InfoItem = (props: {
  title: string;
  value?: string | number;
  minValue?: string | number;
  maxValue?: string | number;
}) => (
  <div className={'grid grid-cols-12'}>
    <div className={'col-start-1 col-end-4 py-2'}>{props.title}</div>
    <div className={'col-start-4 col-end-12 py-2'}>
      {props.value}
      {props.minValue}
      {props.minValue && props.maxValue ? ' - ' : ''}
      {props.maxValue}
    </div>
  </div>
);

const SearchCriteriaModal = ({ isOpen, item, onClose, contactId }: Props) => {
  const { locale } = useLocale();
  const { t } = useTranslation('common');
  const handleOpen = () => {
    console.log('SearchCriteriaModal item', item);
  };

  if (!isOpen) return null;

  return (
    <Modal
      boxClassName="px-14 py-10"
      CloseIconStyle={{
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
      isOpen={isOpen}
      closeModal={onClose}
      openModal={handleOpen}
      buttonProps={{
        title: ' ',
        className: 'pr-6 text-sm leading-4 text-gray-900',
      }}
      withButton={false}
      closeIconSize="medium"
      title={
        <div className="flex items-center justify-center gap-4">
          <p className="text-2xl">{item?.translations?.[locale]?.title}</p>
        </div>
      }
      withTitleDivider={false}
      width={{
        md: '80%',
      }}
    >
      <div className={'divide-y'}>
        {item?.serviceType ? (
          <InfoItem title={t('Deal_type')} value={item?.serviceType.name} />
        ) : null}
        {item?.propertyTypes && item?.propertyTypes.length ? (
          <InfoItem
            title={t('Property_type')}
            value={item?.propertyTypes.map((el) => el.name).join(' ,')}
          />
        ) : null}
        {item?.addresses && item?.addresses.length ? (
          <>
            {item?.addresses.map((el, index) => (
              <InfoItem
                key={el.id}
                title={`${t('Address')} ${index + 1}`}
                value={`${
                  el?.polygon.length
                    ? `Custom location (polygons - ${el?.polygon.length})`
                    : `${el?.translations?.[locale]?.country} ${el?.translations?.[locale]?.city} ${el?.translations?.[locale]?.adresse}`
                }`}
              />
            ))}
          </>
        ) : null}
        {item?.budgetMax || item?.budgetMin ? (
          <InfoItem
            title={t('Budget_CHF')}
            minValue={formatPrice(item?.budgetMin)}
            maxValue={formatPrice(item?.budgetMax)}
          />
        ) : null}
        {item?.surfaceMin || item?.surfaceMax ? (
          <InfoItem
            title={t('Surface_sqm')}
            minValue={item?.surfaceMin}
            maxValue={item?.surfaceMax}
          />
        ) : null}
        {item?.roomsMin || item?.roomsMax ? (
          <InfoItem
            title={t('Rooms')}
            minValue={item?.roomsMin}
            maxValue={item?.roomsMax}
          />
        ) : null}
        {item?.bedroomsMin || item?.bedroomsMax ? (
          <InfoItem
            title={t('Bedrooms')}
            minValue={item?.bedroomsMin}
            maxValue={item?.bedroomsMax}
          />
        ) : null}
        {item?.bathroomsMin || item?.bathroomsMax ? (
          <InfoItem
            title={t('Bathrooms')}
            minValue={item?.bathroomsMin}
            maxValue={item?.bathroomsMax}
          />
        ) : null}
        {item?.floor ? (
          <InfoItem title={t('Floors')} value={item?.floor} />
        ) : null}
        {getItems(item as ExtendItem).length ? (
          <InfoItem
            title={t('Additional_preferences')}
            value={getItems(item as ExtendItem).join(', ')}
          />
        ) : null}
        {item?.conveniences && item?.conveniences.length ? (
          <InfoItem
            title={t('conveniences')}
            value={item?.conveniences.map((el) => el.name).join(', ')}
          />
        ) : null}
      </div>
      <div className={'flex justify-between items-center pt-8'}>
        <Button
          className="cursor-pointer flex justify-between items-center"
          variant="outlined"
          sx={{
            height: '50px',
            border: '1px solid #1D1D1F !important',
            color: '#1D1D1F',
          }}
          href={`/contacts/${contactId}/search-criteria`}
        >
          <EditIcon
            width={'16'}
            height={'16'}
            className="mr-4"
            color="#6E6E72"
          />
          <span className={'mt-1'}>{t('Edit')}</span>
        </Button>
        <Button
          className="cursor-pointer"
          variant="outlined"
          sx={{
            height: '50px',
            border: '1px solid #3446B9 !important',
          }}
          onClick={onClose}
        >
          <span>Ok</span>
        </Button>
      </div>
    </Modal>
  );
};

export default WithModal(SearchCriteriaModal);
