import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

export async function generatePdfWithOverlayHeaderFooter({
  pdfPath,
  logoUrl,
  fileName,
  footerImage,
}: {
  pdfPath: string; // Accept string URL or File object
  logoUrl: string;
  footerImage: string;
  fileName: string;
}): Promise<void> {
  // Fetch the original PDF using downloadFile logic
  // Fetch the original PDF
  const pdfBytes = await fetchBuffer(pdfPath);
  // Load the PDF document
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const pages = pdfDoc.getPages();

  // Fetch and embed the logo image
  const logoBytes = logoUrl ? await fetchBuffer(logoUrl) : null;
  const logoImage = logoBytes ? await pdfDoc.embedPng(logoBytes) : null;
  const logoDimsHeader = logoImage ? logoImage.scale(0.4) : null; // Adjust scale for header

  // Fetch and embed the footer image
  const footerImageBytes = footerImage ? await fetchBuffer(footerImage) : null;
  const footerImageEmbed = footerImageBytes
    ? await pdfDoc.embedPng(footerImageBytes)
    : null;

  // Add header and footer to all pages
  pages.forEach((page) => {
    const { width, height } = page.getSize();

    // Header
    if (logoImage && logoDimsHeader) {
      page.drawRectangle({
        x: 0,
        y: height - 60,
        width,
        height: 60,
        color: rgb(1, 1, 1), // White background
      });

      page.drawImage(logoImage, {
        x: 20,
        y: height - 60 + (60 - logoDimsHeader.height) / 2,
        width: logoDimsHeader.width,
        height: logoDimsHeader.height,
      });
    }

    // Footer
    if (footerImageEmbed) {
      const footerDims = footerImageEmbed.scale(0.4); // Adjust scale for footer
      const footerHeight = footerDims.height; // Use the height of the image for footer
      const footerY = 0; // Align footer at the bottom of the page

      // Draw footer background
      page.drawRectangle({
        x: 0,
        y: footerY,
        width,
        height: footerHeight,
        color: rgb(1, 1, 1), // White background
      });

      // Draw footer image
      page.drawImage(footerImageEmbed, {
        x: 0,
        y: footerY, // Align with the bottom of the page
        width: width,
        height: footerDims.height,
      });
    }
  });

  // Save the modified PDF and download
  const modifiedPdfBytes = await pdfDoc.save();
  const modifiedBlob = new Blob([modifiedPdfBytes], {
    type: 'application/pdf',
  });

  saveAs(modifiedBlob, fileName); // Save modified PDF with provided file name
}

async function fetchBuffer(url: string) {
  const response = await fetch(url);
  if (!response.ok) throw new Error(`Failed to fetch: ${url}`);
  return await response.arrayBuffer();
}
