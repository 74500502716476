import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';
import useLocale from '../../../../../hooks/useLocale';
import { optionType } from '../../../../../const/propertiesOptions';

const PropertyDetailsWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  function yesOrNo(value: boolean) {
    return value ? t('common:yes') : '';
  }

  function extractValue(option: optionType) {
    return option?.translations?.[locale]?.name || option?.keyname;
  }

  function extractMultipleValues(options: optionType[]) {
    return options.map(extractValue).join(', ');
  }

  return (
    <TableWidget
      title={t('common:property-details')}
      items={[
        { id: '1', label: t('floor'), value: `${property.floor}` },
        {
          id: '2',
          label: t('balcony'),
          value: String(property.balcony),
        },
        {
          id: '3',
          label: t('terrace'),
          value: String(property.terrace),
        },

        { id: '4', label: t('pool'), value: yesOrNo(property.pool) },
        { id: '5', label: t('lift'), value: yesOrNo(property.lift) },
        { id: '6', label: t('garden'), value: yesOrNo(property.garden) },
        {
          id: '61',
          label: t('construction_year'),
          value: property.constructionYear
            ? dayjs(property.constructionYear).format('YYYY')
            : '',
        },
        {
          id: '62',
          label: t('Total_number_of_floors'),
          value: String(property.floors),
        },
        {
          id: '7',
          label: t('renovation_year'),
          value: property.renovationYear
            ? dayjs(property.renovationYear).format('YYYY')
            : '',
        },
        { id: '8', label: t('state'), value: extractValue(property.state) },
        {
          id: '81',
          label: t('orientation'),
          value: extractMultipleValues(property.orientations),
        },
        {
          id: '9',
          label: t('sunlight'),
          value: extractValue(property.sunlight),
        },
        {
          id: '12',
          label: t('view'),
          value: extractMultipleValues(property.views),
        },
        {
          id: '10',
          label: t('heating_type'),
          value: extractValue(property.heatingType),
        },
        {
          id: '101',
          label: t('energy'),
          value: extractValue(property.energy),
        },
        {
          id: '12',
          label: t('common:hotWater'),
          value: extractValue(property.hotWater),
        },
        {
          id: '11',
          label: t('sound_level'),
          value: extractValue(property.soundLevel),
        },
        {
          id: '13',
          label: t('Glass'),
          value: extractValue(property.glass),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyDetailsWidget;
