import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { Settings } from './index';

const PriceHubbleReportSettings = ({
  settings,
  initialSettings,
  radioSelection,
  setSettings,
  setRadioSelection,
}: {
  settings: Settings[];
  initialSettings: Settings[];
  radioSelection: string;
  setSettings: (
    settings: (prevSettings: Settings[]) => {
      configurations?: string[];
      switches?: string[];
      keyname: string;
      title: string;
      enabled: boolean;
    }[]
  ) => void;
  setRadioSelection: (selection: string) => void;
}) => {
  const { t } = useTranslation('propertiesPage');

  // Handle toggle for the main Switch
  const handleToggle = (index: number) => {
    setSettings((prevSettings: Settings[]) =>
      prevSettings.map((setting, i) =>
        i === index ? { ...setting, enabled: !setting.enabled } : setting
      )
    );
  };

  // Handle toggle for the checkboxes
  const handleCheckboxToggle = (index: number, category: string) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting, i) => {
        if (i === index && setting.configurations) {
          const newConfigurations = setting.configurations.includes(category)
            ? setting.configurations.filter((config) => config !== category) // Uncheck
            : [...setting.configurations, category]; // Check
          return { ...setting, configurations: newConfigurations };
        }
        return setting;
      })
    );
  };

  // Handle radio button selection
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelection(event.target.value);
  };

  return (
    <div>
      <p className={'mb-8 text-lg font-medium'}>{t('common:Settings')}:</p>
      <div className={'flex flex-col gap-4'}>
        {initialSettings.map((setting, index) => (
          <div key={index} className={'relative'}>
            {/*this is a hack to make the switch overlap the accordion*/}
            {!setting.switches && (
              <div className={'absolute top-[12px] right-8 z-10'}>
                <Switch
                  edge="end"
                  checked={settings[index]?.enabled}
                  onChange={() => handleToggle(index)}
                />
              </div>
            )}
            <Accordion
              sx={{
                margin: '0',
                boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                '&': {
                  width: '100%',
                },
                '&::before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'inherit',
                },
              }}
              defaultExpanded={!!setting.switches}
            >
              <AccordionSummary
                sx={{
                  padding: '1rem',
                  backgroundColor: '#FBFBFD',
                  '&.Mui-expanded': {
                    minHeight: '48px',
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '0',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingRight: '1rem',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded .icon-accordion-rotated':
                    {
                      transform: 'rotate(180deg)',
                    },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '0',
                    alignItems: 'center',
                  },
                }}
                className={'flex items-center justify-between'}
                expandIcon={null}
              >
                <div className={'flex items-center gap-8'}>
                  <p className={'text-lg font-medium'}>{t(setting.title)}</p>
                  {setting.configurations || setting.switches ? (
                    <ExpandMoreIcon className={'icon-accordion-rotated'} />
                  ) : null}
                </div>
              </AccordionSummary>
              {setting.configurations && setting.configurations.length > 0 && (
                <AccordionDetails
                  style={{
                    boxShadow: 'none',
                    borderTop: '1px solid #E5E7EB',
                  }}
                >
                  <p className={'mb-4 pt-4 font-medium'}>{t('Categories')}:</p>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      opacity: settings[index]?.enabled ? 1 : 0.7,
                    }}
                  >
                    {setting.configurations.map((category) => (
                      <FormControlLabel
                        key={category}
                        control={
                          <Checkbox
                            checked={settings?.[
                              index
                            ]?.configurations?.includes(category)}
                            onChange={() =>
                              handleCheckboxToggle(index, category)
                            }
                            disabled={!settings[index]?.enabled}
                          />
                        }
                        label={t(category)}
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              )}
              {setting.switches && setting.switches.length > 0 && (
                <AccordionDetails
                  style={{
                    boxShadow: 'none',
                    borderTop: '1px solid #E5E7EB',
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: '1.5rem',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      opacity: settings[index]?.enabled ? 1 : 0.7,
                    }}
                  >
                    <RadioGroup
                      sx={{
                        flexDirection: 'row',
                      }}
                      value={radioSelection}
                      onChange={handleRadioChange}
                    >
                      {setting.switches.map((category) => (
                        <FormControlLabel
                          key={category}
                          value={category}
                          control={<Radio />}
                          label={t(category)}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </AccordionDetails>
              )}
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceHubbleReportSettings;
