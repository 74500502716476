import useSWR from 'swr';
import useLocale from '../../../../../hooks/useLocale';
import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import { useAlignmentStyles } from '../../../lib/hooks';
import { FileNode } from '../../../../../types/hierarchy/legacy';
import { useTranslation } from 'react-i18next';
import { standardizePhoneNumber } from '../../../../../helpers/utils/formatters';
import WidgetTitle from '../../widgetTitle';

const AgencyBlock = ({ previewSubject, widgetOptions }: WidgetPreviewProps) => {
  const { locale } = useLocale();

  const withLogo = widgetOptions?.withLogo?.value;
  const withEmail = widgetOptions?.withEmail?.value;
  const withPhone = widgetOptions?.withPhone?.value;
  const withService = widgetOptions?.withService?.value;
  const withAddress = widgetOptions?.withAddress?.value;

  const { data: department } = useSWR<FileNode>(
    previewSubject.department
      ? `/departments/${previewSubject.department?.id}`
      : null
  );

  const service = department?.service?.translations?.[locale]?.name || '';
  const agency = department?.agency;
  const wrapperGridStyle = useAlignmentStyles(widgetOptions);

  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-4">
      <WidgetTitle title={t('Contact')} widgetOptions={widgetOptions} />

      <div
        className="grow grid gap-4 p-8 bg-neutral-100"
        style={wrapperGridStyle}
      >
        {withLogo && (
          <div>
            {agency?.logo?.x96 ? (
              <img
                className="block w-full h-[88px] object-contain"
                src={agency.logo.x96}
                alt=""
              />
            ) : (
              <span
                className="w-[88px] h-[88px] rounded-full text-white grid place-items-center uppercase text-[2rem]"
                style={{ backgroundColor: agency?.brand || '#3446B9' }}
              >
                {getTwoLetterName(agency?.name)}
              </span>
            )}
          </div>
        )}

        <div className="grid gap-1" style={wrapperGridStyle}>
          <div className="text-2xl leading-7">{agency?.name}</div>
          {withService && (
            <span className="text-sm leading-none">{service}</span>
          )}
        </div>

        {(withEmail || withPhone || withAddress) && (
          <div className="grid gap-1" style={wrapperGridStyle}>
            {withEmail && (
              <a
                href={`mailto:${agency?.email}`}
                className="text-sm leading-none"
              >
                {agency?.email}
              </a>
            )}

            {withPhone && (
              <a
                href={`tel:${agency?.phone}`}
                className="inline-flex text-sm leading-none"
              >
                {standardizePhoneNumber(agency?.phone)}
              </a>
            )}

            {withAddress && (
              <>
                <span className="text-sm leading-none empty:hidden">
                  {agency?.location}
                </span>
                <span className="text-sm leading-none empty:hidden">
                  {[agency?.cp, agency?.city].filter(Boolean).join(' ')}
                </span>
              </>
            )}

            <span className="text-sm leading-none underline">
              {agency?.website}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgencyBlock;

function getTwoLetterName(fullName = ''): string {
  const words = fullName.split(' ').filter((item) => item.match(/[A-Za-z]+/));
  const [firstWord, secondWord] = words;
  if (firstWord && secondWord) {
    return `${firstWord[0]}${secondWord[0]}`;
  } else if (firstWord) {
    return firstWord.slice(0, 2);
  }
  return '';
}
