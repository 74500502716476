import { MouseEventHandler } from 'react';

import RemoveButton from '../components/removeButton';
import LazyPdfPreview from '../../common/lazyPdfPreview';
import { confirmDelete } from '../../../pages/properties/utils';

const DocumentItem = ({
  url,
  onTrashIconClick,
}: {
  url: string;
  onTrashIconClick?: MouseEventHandler;
}) => {
  return (
    <div className="h-[180px] grid place-items-center border border-gray-200 bg-gray-50 rounded relative overflow-hidden child-canvas-h-180">
      <LazyPdfPreview
        documentProps={{ file: url }}
        thumbnailProps={{ height: 180 }}
        skeletonHeight={180}
      />

      <RemoveButton
        tooltip="Remove PDF"
        onClick={() => confirmDelete(onTrashIconClick as MouseEventHandler)}
      ></RemoveButton>
    </div>
  );
};

export default DocumentItem;
