import { isArray, pickBy } from 'lodash';
import { initialFilterValuesI } from '../../types/filter';
import { countries } from '../../const/countries';
import { getCountry } from '../agencies/tree/utils';
import { Locale } from '../../hooks/useLocale';
import { IAddress } from '../../types/address';
import {
  SingleLocaleTranslation,
  Translations,
} from '../../types/translations';
import { t } from 'i18next';
import Swal from 'sweetalert2';

const convertDisplayedPrice = (amount: string): string => {
  if (/\d{3}\d+/.test(amount)) {
    return convertDisplayedPrice(amount.replace(/(\d{3}?)('|$)/, "'$&"));
  }
  return amount;
};

const convertToClient = (
  serverData: any,
  locale: Locale
): initialFilterValuesI | null => {
  if (!serverData) return null;
  const server_Data = pickBy(serverData, (value) => {
    return isArray(value)
      ? value.length > 0
      : value !== undefined && value !== null && value !== '';
  });

  /* eslint-disable */
  const { address, reference, departments, translations, agents, ...rest } =
    server_Data;
  let result = {
    ...rest,
  };

  if (address) {
    const { translations: adressTranslation } = address;

    const { city, adresse, country, canton, district } =
      adressTranslation[`${locale}`];
    result = {
      ...result,
      city: city || '',
      adresse: adresse || '',
      zipCode: address?.zipCode || '',
      canton: canton || '',
      district: district || '',
      country: getCountry(countries, country)[locale],
    };
  }
  if (translations) {
    result = {
      ...result,
      name: translations[`${locale}`]?.name || '',
      title: translations[`${locale}`]?.title || '',
    };
  }
  if (reference) {
    result = {
      ...result,
      reference: reference.map((elm: string) => {
        return {
          id: elm,
          firstname: elm,
          label: elm,
          lastname: '',
          value: elm,
        };
      }),
    };
  }
  if (departments) {
    result.departments = departments.map((dept: number) => String(dept));
  }
  if (agents) {
    result.agents = agents.length > 0 ? agents[0] : [];
  }
  return result;
};

const convertToServerAdress = (clientData: any, local: string) => {
  const {
    adresse,
    city,
    zipCode,
    country,
    canton,
    address,
    district,
    ...rest
  } = clientData;
  const result = {
    ...rest,
  };
  // console.log('newdataTosend clientData', address);
  if (adresse || city || zipCode || country || canton || district) {
    result.address = {
      zipCode: zipCode || null,
      translations: {
        [local]: {
          country: country || null,
          adresse: adresse || null,
          city: city || null,
          locale: local,
          canton: canton || null,
          district: district || null,
        },
      },
    };
  }
  return result;
};

export function findCountryAlpha2(lang: string, fullName: unknown) {
  const matchingCountry = countries.find(
    (country: any) => country[lang] === fullName
  );

  if (matchingCountry) {
    return matchingCountry.alpha2;
  }

  return null; // Return null if no matching country is found
}

export { convertDisplayedPrice, convertToClient, convertToServerAdress };

export function addressIsEmpty(address: IAddress) {
  return (
    emptyVal(address.latitude) &&
    emptyVal(address.longitude) &&
    emptyVal(address.zipCode) &&
    translationsAreEmpty(address.translations)
  );
}

export function translationsAreEmpty(translations: Translations) {
  return Object.values(translations).every(localeTranslationIsEmpty);
}

export function localeTranslationIsEmpty(
  localeTranslation: SingleLocaleTranslation
) {
  return Object.entries(localeTranslation).every(
    ([key, value]) => key === 'locale' || emptyVal(value)
  );
}

// emptyVal(0) => true
// emptyVal('0') => true
// emptyVal('') => false
// emptyVal(null) => false
// emptyVal(undefined) => false
function emptyVal(value: string | number | null | undefined) {
  return value === '' || value === null || value === undefined;
}

export const confirmDelete = async (deleteFunction: Function) => {
  const { isConfirmed } = await Swal.fire({
    text: t('common:Are_you_sure_you_want_to_permanently_delete_this_item'),
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: t('common:yes'),
    cancelButtonText: t('common:Cancel'),
    customClass: {
      cancelButton: 'order-1 cancel-btn',
      confirmButton: 'order-2 confirm-btn',
    },
  });
  if (isConfirmed) {
    deleteFunction();
  }
};
