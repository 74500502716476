import { Box } from '@mui/material';
import { useState, ReactNode } from 'react';
import sortBy from 'lodash/sortBy';
import { FileRejection, useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { http } from '../../../libs/axios';
import { IMedia } from '../../../types/api';
import DocumentItem from './documentItem';
import GenericDocumentPreview from './genericPreview';
import RemoveButton from '../components/removeButton';
import { useValidateFile } from '../../../hooks/useValidateFile';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../../pages/properties/utils';

type Props = {
  fileUploadUrl: string;
  media: IMedia[];
  isMultiple?: boolean;
  name: string;
  mutate?: () => Promise<unknown>;
  singleDeleteUrl?: string;
  accept?: Record<string, string[]>;
  maxSizeMb?: number;
};

const defaultAccept = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.doc',
    '.docx',
  ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xls',
    '.xlsx',
  ],
};

const DocumentsField = ({
  fileUploadUrl,
  isMultiple = false,
  name,
  media,
  mutate,
  singleDeleteUrl,
  accept = defaultAccept,
  maxSizeMb = 50,
}: Props) => {
  const [spinnerCount, addSpinners] = useState(0);
  const [idsToBeDeleted, setIdsToBeDeleted] = useState<number[]>([]);
  const { t } = useTranslation('common');
  const validateFile = useValidateFile({ maxSizeMb });

  async function handleFileUpload(files: File[]) {
    addSpinners(files.length);

    const formData = new FormData();

    if (isMultiple) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && validateFile(file)) {
          formData.append('files[]', file);
        }
      }
    } else if (files[0] && validateFile(files[0])) {
      formData.append('file', files[0]);
    }

    await http.post(fileUploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (mutate) await mutate();

    addSpinners(0);
  }

  async function removeFile(id: number) {
    setIdsToBeDeleted([...idsToBeDeleted, id]);

    if (!isMultiple && singleDeleteUrl) {
      await http.patch(singleDeleteUrl);
    } else {
      await http.delete(`/media/${id}`);
    }

    if (mutate) await mutate();

    const index = idsToBeDeleted.indexOf(id);
    if (index >= 0) {
      setIdsToBeDeleted([
        ...idsToBeDeleted.slice(0, index - 1),
        ...idsToBeDeleted.slice(index),
      ]);
    }
  }

  const spinners: ReactNode[] = [];
  for (let i = 0; i < spinnerCount; i++) {
    spinners.push(
      <div key={i * -1} style={{ position: 'relative' }}>
        <div
          style={{
            border: '1px solid #E6E8EC',
            padding: '4px',
            // width: '168px',
            height: '180px',
            objectFit: 'contain',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <SpinnerLoading />
        </div>
      </div>
    );
  }

  const handleReject = (rejections: FileRejection[]) => {
    for (const rejection of rejections) {
      const { errors } = rejection;
      for (const error of errors) {
        const { message } = error;
        toast.error(message);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple: isMultiple,
    onDrop: handleFileUpload,
    onDropRejected: handleReject,
  });

  return (
    <Box className="relative">
      <div className="grid grid-cols-2 2xl:grid-cols-3 gap-8">
        {sortBy(media, 'sort').map((media) => (
          <div
            key={media.id}
            className={classNames('relative', {
              'opacity-50 pointer-events-none': idsToBeDeleted.includes(
                media.id
              ),
            })}
          >
            {media.type === 'application/pdf' ? (
              <DocumentItem
                key={media.id}
                url={media.url.original}
                onTrashIconClick={() => removeFile(media.id)}
              />
            ) : (
              <>
                <GenericDocumentPreview
                  icon={
                    <div className="relative">
                      <svg
                        width="34"
                        height="40"
                        viewBox="0 0 34 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33 12.2L21.8005 1H2.59992C2.1756 1 1.76865 1.16857 1.46861 1.46863C1.16856 1.76869 1 2.17566 1 2.6V37.8C1 38.2243 1.16856 38.6313 1.46861 38.9314C1.76865 39.2314 2.1756 39.4 2.59992 39.4H31.4001C31.6102 39.4 31.8182 39.3586 32.0123 39.2782C32.2065 39.1978 32.3828 39.0799 32.5314 38.9314C32.68 38.7828 32.7978 38.6064 32.8782 38.4123C32.9586 38.2182 33 38.0101 33 37.8V12.2ZM33 12.2L21.7984 12.2V0.999997"
                          stroke="#1D1D1F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="absolute left-1 bottom-1 right-1 truncate text-[9px]">
                        {parseFileType(media.url.original)}
                      </div>
                    </div>
                  }
                  text={parseFileName(media.url.original)}
                />
                <RemoveButton
                  tooltip="Remove file"
                  onClick={() => confirmDelete(() => removeFile(media.id))}
                />
              </>
            )}
          </div>
        ))}

        {spinners}

        <div {...getRootProps()} className="cursor-pointer">
          <GenericDocumentPreview
            isActive={isDragActive}
            classes="hover:border-black"
            icon={
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 3V29" stroke="#1D1D1F" strokeLinecap="round" />
                <path d="M3 16L29 16" stroke="#1D1D1F" strokeLinecap="round" />
              </svg>
            }
            text={
              <p className="text-gray-900">
                {t('Drag')} &amp; {t('drop_a_file_or')}{' '}
                {/* <span className="text-blue underline cursor-pointer underline-offset-3"> */}
                <span>
                  {t('browse')}...
                  <input
                    {...getInputProps({
                      style: { display: 'none' },
                      id: name,
                      multiple: isMultiple,
                      type: 'file',
                    })}
                  />
                </span>
              </p>
            }
          />
        </div>
      </div>
    </Box>
  );
};
export default DocumentsField;

function parseFileType(fullServerName: string): string {
  const type = fullServerName.split('.').pop();
  return type ? `.${type}` : '';
}

function parseFileName(fullServerName: string): string {
  const fileName = fullServerName.split('/').pop();
  if (!fileName) return '';

  const matches = fileName.match(/(.+)-[a-f0-9]{13}\.[a-z]{2,}/);
  if (Array.isArray(matches) && typeof matches[1] === 'string') {
    return matches[1] + parseFileType(fileName);
  }
  return fullServerName;
}
