import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../../../../layout/errorBoundary';

function BrochureEditorLayout({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary>
      <Helmet>
        <link rel="stylesheet" href="/fonts/HelveticaNeueCyr-Roman/style.css" />
        <link
          rel="stylesheet"
          href="/fonts/HelveticaNeueCyr-Medium/style.css"
        />
        <link rel="stylesheet" href="/fonts/HelveticaNeueCyr-Bold/style.css" />
      </Helmet>

      {children}
    </ErrorBoundary>
  );
}

export default BrochureEditorLayout;
