import { Box, Skeleton, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { KeyedMutator } from 'swr';

import { useTranslation } from 'react-i18next';
import { ILeadItem, IPromotionLeadItem } from '../../types/contacts';
import {
  ContactCell,
  DateCell,
  SourceCell,
  StatusCell,
  TypeCell,
} from '../index/utils/leadTableCells';
import useLocale from '../../hooks/useLocale';
import LeadActionsMenu from '../contacts/singleContactPage/utils/leadActions';
import PermissionIcon from '../../features/icons/permissionIcon';
import { Link } from 'react-router-dom';

export const useLeadsListColumns = ({
  isLoading,
  mutate,
}: {
  isLoading?: boolean;
  mutate?: KeyedMutator<ILeadItem>;
}): GridColumns<ILeadItem> => {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };
  const findImagesTab = (item: ILeadItem): string | undefined => {
    if (Array.isArray(item?.property.media)) {
      return undefined;
    }
    const images = item?.property.media.images;
    if (images && images.length > 0) {
      const mainImage = images.find((elem) => elem.isMain);
      return mainImage ? mainImage.url.large : images[0]?.url.large;
    }
    return undefined;
  };

  const columns: GridColumns<ILeadItem> = [
    {
      field: 'Reference',
      cellClassName: '!pl-3 !py-1',
      width: 265,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <span>/</span>

          <span>{t('common:Reference')} </span>
        </Box>
      ),
      renderCell: ({ row }) => {
        const promoItem = row?.promotion as IPromotionLeadItem;
        return (
          <div className="w-full flex flex-wrap h-full">
            {promoItem ? (
              <Link
                to={`/promotions/${promoItem?.id}`}
                id={`img_promotion_${promoItem?.id}`}
                className="block  w-[100%] h-full"
              >
                <div className="w-full flex flex-wrap h-full justify-between">
                  <div className="w-[35%] h-full">
                    <div className="h-full max-w-[100%] min-w-[100%]">
                      <img
                        src={
                          promoItem?.coverImage?.url?.thumbnail ||
                          '/img/default_image.jpg'
                        }
                        alt=""
                        className="!object-cover max-w-inherit min-w-[100%] h-full"
                      />
                    </div>
                  </div>
                  <div className="w-[62%] flex flex-col justify-around">
                    <Tooltip
                      title={promoItem?.translations?.[locale]?.title}
                      arrow
                      placement="top"
                    >
                      <span className="block truncate font-[Inter] font-medium text-sm">
                        {promoItem?.translations?.[locale]?.title}
                      </span>
                    </Tooltip>
                    <div>
                      <div
                        className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
                        style={{ border: '1px solid #3446B9 ' }}
                      >
                        <PermissionIcon
                          className=" col-span-1"
                          color="#3446B9"
                        />{' '}
                        <span className="text-blue inter leading-4 font-normal text-sm">
                          {promoItem?.reference}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Link
                to={`/properties/${row?.property?.id}`}
                id={`img_property_${row?.property?.id}`}
                className="block  w-[100%] h-full"
              >
                <div className="w-full flex flex-wrap h-full justify-between">
                  <div className="w-[35%] h-full">
                    <div className="h-full max-w-[100%] min-w-[100%]">
                      <img
                        src={findImagesTab(row) || '/img/default_image.jpg'}
                        alt=""
                        className="!object-cover max-w-inherit min-w-[100%] h-full"
                      />
                    </div>
                  </div>
                  <div className="w-[62%] flex flex-col justify-around">
                    <Tooltip
                      title={row?.property?.translations?.[locale]?.title}
                      arrow
                      placement="top"
                    >
                      <span className="block truncate font-[Inter] font-medium text-sm">
                        {row?.property?.translations?.[locale]?.title}
                      </span>
                    </Tooltip>
                    <div>
                      <div
                        className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
                        style={{ border: '1px solid #3446B9 ' }}
                      >
                        <PermissionIcon
                          className=" col-span-1"
                          color="#3446B9"
                        />{' '}
                        <span className="text-blue inter leading-4 font-normal text-sm">
                          {row?.property?.reference}
                        </span>
                      </div>
                    </div>
                    {row?.property?.agencyReference && (
                      <Tooltip
                        title={`${t('Reference_Agency')}: ${
                          row?.property?.agencyReference
                        }`}
                        arrow
                        placement="top"
                      >
                        <div
                          className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                          style={{ border: '1px solid #00bfff' }}
                        >
                          <PermissionIcon
                            className="w-[25px] col-span-1"
                            color="#00bfff"
                          />
                          <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                            {row?.property?.agencyReference}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Link>
            )}
          </div>
        );
      },
    },
    {
      field: 'date',
      headerName: t('common:Date'),
      width: 120,
      renderCell: ({ row }) => (
        <DateCell item={row} wrapperClassName={'h-full pt-2'} />
      ),
    },
    {
      width: 100,
      field: 'type',
      renderHeader: () => (
        <Tooltip title={t('common:Deal_type')} arrow placement="top">
          <span className="font-[Inter] truncate block text-sm font-medium">
            {t('common:Deal_type')}
          </span>
        </Tooltip>
      ),
      renderCell: ({ row }) => (
        <TypeCell
          item={row}
          locale={locale}
          wrapperClassName={'h-full pt-2 pl-[6px]'}
        />
      ),
    },
    {
      width: 150,
      field: 'status',
      headerName: t('common:Status'),
      renderCell: ({ row }) => (
        <StatusCell
          item={row}
          withSendEmail
          wrapperClassName={'h-full pt-2 pl-[6px]'}
        />
      ),
    },

    {
      width: 130,
      field: 'agency',
      headerName: t('common:Agency'),
      renderCell: ({ row }) => {
        const promoItem = row?.promotion as IPromotionLeadItem;
        return (
          <div className="w-full h-full pt-2">
            {promoItem ? (
              promoItem?.departments.map(
                (elem: { name: string }, index: number) => {
                  const isLast = index === promoItem.departments.length - 1;
                  return (
                    <Tooltip
                      key={index}
                      title={elem.name}
                      arrow
                      placement="top"
                    >
                      <span className="font-[Inter] truncate block text-sm font-medium">
                        {elem.name + (isLast ? '' : ',')}
                      </span>
                    </Tooltip>
                  );
                }
              )
            ) : (
              <Tooltip
                title={row.property.department.name}
                arrow
                placement="top"
              >
                <span className="font-[Inter] truncate block text-sm font-medium">
                  {row.property.department.name}
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },

    {
      width: 130,
      field: 'contact',
      headerName: t('common:Contact'),
      renderCell: ({ row }) => (
        <ContactCell item={row} wrapperClassName={'h-full pt-2'} />
      ),
    },
    {
      width: 200,
      field: 'source',
      headerName: t('common:Source'),
      renderCell: ({ row }) => (
        <SourceCell
          item={row}
          customHeight
          wrapperClassName={'h-full pt-2 !text-xs'}
        />
      ),
    },
    {
      field: 'message',
      width: 190,
      headerName: t('common:Message'),
      renderCell: ({ row }) => (
        <div className="h-full w-full pt-2 mb-1 ml-1">
          <Tooltip title={row.message} arrow placement="top">
            <span
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
              }}
              className=" font-[Inter]  block w-[100%] text-sm font-medium"
            >
              {row.message}
            </span>
          </Tooltip>{' '}
        </div>
      ),
    },
    {
      field: 'action',
      width: 100,
      headerClassName: 'hideRightSeparator',
      headerName: t('common:Action'),
      renderCell: ({ row }) =>
        row.property && (
          <LeadActionsMenu
            row={{
              ...row,
              parentId: row.contact ? row.contact.id.toString() : '',
            }}
            mutateForStatus={mutate}
            withSendFiles
          />
        ),
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
