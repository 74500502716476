import EraseIcon from '../../../../../features/icons/eraseIcon';
import PlaceIcon from '@mui/icons-material/Place';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useAnchorElement } from '../../../../../hooks/useAnchorElement';
import { ActionData } from '../../../../../types/actionData';
import ActionMenu from '../../../../../components/common/actionMenu';
import { confirmDelete } from '../../../utils';

const DistancesActionMenu = ({
  isMapVisible,
  onGetPositionsClick,
  onFormClean,
  onDeleteItem,
  enableClearButton,
  isLastItem,
}: any) => {
  const anchor = useAnchorElement();

  const menuActionData: ActionData[] = [
    {
      label: isMapVisible ? 'Close_map' : 'Get_positions',
      icon: isMapVisible ? CloseIcon : PlaceIcon,
      onClick: onGetPositionsClick,
      class: '!hidden md:!block',
    },
    {
      label: 'Clear',
      icon: EraseIcon,
      onClick: onFormClean,
      class: '!hidden md:!block',
      disabled: !enableClearButton,
    },
    {
      label: 'Delete',
      icon: DeleteOutlineIcon,
      onClick: () => confirmDelete(onDeleteItem),
      class: '!hidden md:!block',
      disabled: isLastItem,
    },
  ];

  return (
    <ActionMenu
      {...anchor}
      menuAction={menuActionData}
      transPage={'propertiesPage'}
    />
  );
};

export default DistancesActionMenu;
