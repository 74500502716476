import classNames from 'classnames';
import { CSSProperties, ReactNode, createElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  usePagesEditorComputables,
  usePagesEditor,
} from '../../lib/hooks/usePagesEditor';
import { usePreviewSubject } from '../../lib/hooks/usePreviewSubject';
import { widgetTypes } from '../../lib/widgetTypes';
import {
  Page,
  PageTypes,
  PreviewSubjectTypes,
  REPEATABLE_AREA,
  TemplateOptions,
  WidgetDetails,
} from '../../../../types/brochureEditor';
import { BRAND_COLOR_PROP } from '../controls/color';
import { IPropertyApi } from '../../../../types/property';
import { IPromotionApi } from '../../../../types/promotion';

import Sheet from '../sheet';
import Mockup from '../mockup';
import { standartizeWidgetOptionValues } from '../../lib/utils';

const SinglePage = ({
  page,
  multipageProps,
  isFirst = false,
}: {
  page?: Page | null;
  multipageProps?: unknown;
  isFirst?: boolean;
}) => {
  const { editedAreaId, setEditedArea, overrides, editedLayoutPartId } =
    usePagesEditor();
  const { header, footer, isEditingLayout } = usePagesEditorComputables();
  const isRepeatable = page?.type === PageTypes.REPEATABLE;

  const { watch } = useFormContext<TemplateOptions>();

  const { t } = useTranslation('brochureEditor');

  const {
    previewSubject,
    type: actualSubjectType,
    originalSubject,
  } = usePreviewSubject();

  return (
    <>
      {page && (
        <div
          className="border border-gray-200 w-[928px] font-medium relative font-['Helvetica_Neue']"
          style={{ [BRAND_COLOR_PROP]: watch('brandColor') } as CSSProperties}
        >
          <Sheet>
            {page.mockup && (
              <Mockup
                page={page}
                gridTemplateAreas={page.mockup}
                withLayoutStyles
                displayGeneratedDateIfItIsEnabled={!isFirst}
                // displayGeneratedDateIfItIsEnabled={!page.skipGeneratedDate}
                renderCell={({
                  area,
                  isHeader,
                  isFooter,
                  justifyContent,
                  index,
                }) => {
                  let source: Page | undefined = page;
                  if (isHeader) {
                    source = header;
                  } else if (isFooter) {
                    source = footer;
                  }

                  const areaToCheck = isRepeatable ? REPEATABLE_AREA : area;
                  const widget = standartizeWidgetOptionValues(
                    source?.widgets?.[areaToCheck]
                  );
                  const widgetType = widget?.type || '';
                  const widgetDetails = (widgetTypes[widgetType] ||
                    widgetTypes.blank) as WidgetDetails;

                  const widgetOverrides =
                    overrides[previewSubject?.id || '']?.[page?.id || '']
                      ?.widgets?.[areaToCheck]?.options;

                  let preview: ReactNode | null = null;
                  const widgetOptions = {
                    ...widget?.options,
                    ...widgetOverrides,
                  };

                  if (widgetDetails.preview.independent) {
                    preview = createElement(widgetDetails.preview.independent, {
                      widgetOptions,
                      index,
                      multipageProps,
                    });
                  } else if (
                    widgetDetails.preview.universal &&
                    previewSubject
                  ) {
                    preview = createElement(widgetDetails.preview.universal, {
                      previewSubject,
                      widgetOptions,
                      index,
                      multipageProps,
                    });
                  } else if (
                    widgetDetails.preview.property &&
                    previewSubject &&
                    actualSubjectType === PreviewSubjectTypes.PROPERTY
                  ) {
                    preview = createElement(widgetDetails.preview.property, {
                      property: originalSubject as IPropertyApi,
                      widgetOptions,
                      index,
                      multipageProps,
                    });
                  } else if (
                    widgetDetails.preview.promotion &&
                    previewSubject &&
                    actualSubjectType === PreviewSubjectTypes.PROMOTION
                  ) {
                    preview = createElement(widgetDetails.preview.promotion, {
                      promotion: originalSubject as IPromotionApi,
                      widgetOptions,
                      index,
                      multipageProps,
                    });
                  } else if (widgetType === 'blank' || !widgetType) {
                    preview = <div></div>;
                  }

                  const isInsidePage =
                    !isEditingLayout && !isHeader && !isFooter;
                  const isEditingHeader =
                    editedLayoutPartId === header?.id && isHeader;
                  const isEditingFooter =
                    editedLayoutPartId === footer?.id && isFooter;
                  const isActivePart =
                    isInsidePage || isEditingHeader || isEditingFooter;

                  const isActiveArea = isActivePart && area === editedAreaId;

                  const thisIsLayoutPart = isHeader || isFooter;

                  if (preview) {
                    return (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditedArea(
                            area,
                            thisIsLayoutPart ? source?.id : undefined
                          );
                        }}
                        className={classNames(
                          'relative border-2 border-dashed grid overflow-hidden',
                          {
                            'border-blue': isActiveArea,
                            'border-transparent hover:border-gray-200 cursor-pointer':
                              !isActiveArea,
                            'content-center': thisIsLayoutPart,
                          }
                        )}
                        style={{ justifyContent }}
                      >
                        {preview}
                      </div>
                    );
                  }

                  return (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditedArea(
                          area,
                          thisIsLayoutPart ? source?.id : undefined
                        );
                      }}
                      className={classNames(
                        'relative border-2 border-dashed grid place-items-center',
                        {
                          'hover:border-blue': !widget && !isActiveArea,
                          'border-blue': isActiveArea,
                          ' border-gray-200 cursor-pointer': !isActiveArea,

                          'bg-gray-50': !widget && isActiveArea,
                          'bg-gray-100': widget,
                        }
                      )}
                    >
                      {widget && (
                        <div className="w-32 max-w-full px-4 py-8 bg-gray-50 rounded flex-col justify-center items-center gap-2 inline-flex">
                          <div className="w-6 h-6 justify-center items-center inline-flex">
                            <div className="w-6 h-6 relative">
                              {widgetDetails.icon &&
                                createElement(widgetDetails.icon)}
                            </div>
                          </div>
                          <div className="text-xs font-normal leading-3 text-center">
                            {t(widgetDetails.name)}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            )}
          </Sheet>
        </div>
      )}
    </>
  );
};

export default SinglePage;
