import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { Button, Typography } from '@mui/material';

import { ISettings } from '../../../../../../types/settings';
import InputFormField from '../../../../../../components/form/inputFormField';
import { Locale } from '../../../../../../hooks/useLocale';
import PlusIcon from '../../../../../../features/icons/plusIcon';
import CloseIcon from '../../../../../../features/icons/closeIcon';
import { useTranslation } from 'react-i18next';

type Props = {
  nestIndex: number;
  control: Control<ISettings, any>;
  register: UseFormRegister<ISettings>;
  locale: Locale;
  watch: UseFormWatch<ISettings>;
  setValue?: UseFormSetValue<any>;
};

const AttributesOptions = ({
  nestIndex,
  control,
  register,
  setValue,
  locale,
  watch,
}: Props) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `dataFields.${nestIndex}.fieldSelect.options`,
  });
  const { t } = useTranslation('propertiesPage');
  return (
    <div className="grid gap-2">
      {fields.map((item, index) => {
        return (
          <InputFormField
            key={item.id}
            label={`${t('List_item')} ${addZero(index)}`}
            type="text"
            required={index !== 0}
            withIcon
            icon={
              <div onClick={() => remove(index)}>
                <CloseIcon color="#F50057" />
              </div>
            }
            {...register(
              `dataFields.${nestIndex}.fieldSelect.options.${index}.translations.${locale}.value`,
              {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue &&
                    setValue(
                      `dataFields.${nestIndex}.fieldSelect.options.${index}.translations.${
                        locale === 'en' ? 'fr' : 'en'
                      }.value`,
                      e.target.value
                    );
                },
              }
            )}
            value={watch(
              `dataFields.${nestIndex}.fieldSelect.options.${index}.translations.${locale}.value`
            )}
          />
        );
      })}
      <div className="mt-6">
        <Button
          variant="outlined"
          type="button"
          onClick={() =>
            append({
              translations: {
                [`en`]: {
                  name: '',
                  value: '',
                  label: '',
                  locale: 'en',
                },
                [`fr`]: {
                  name: '',
                  label: '',
                  value: '',
                  locale: 'fr',
                },
                [`de`]: {
                  name: '',
                  label: '',
                  value: '',
                  locale: 'de',
                },
              },
            })
          }
          style={{ width: '100%', height: '42px', background: '#F5F5F7' }}
          className="w-full relative"
        >
          <PlusIcon color="#6E6E72" className="absolute left-4" />

          <Typography
            sx={{
              fontSize: '14px',
              color: '#6E6E72',
              fontWeight: 500,
            }}
          >
            {t('Add_custom_field')}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default AttributesOptions;

function addZero(index: number) {
  if (index >= 0 && index < 9) {
    return `0${index + 1}`;
  }
  return index;
}
