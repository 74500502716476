import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useMedia } from 'react-use';
import { useSearchParams } from 'react-router-dom';

import { ApiListing } from '../types/api';
import { FilterApiBody } from '../types/filter';

import { http } from '../libs/axios';
import i18n from '../i18n';

type Props = {
  basedUrl: string;
  action?: string;
  isActiveProperties?: boolean;
  status?: string | null;
  filter?: string | null;
  currentPage?: string;
  isChecked?: boolean;
  initialItemsPerPage?: number;
};

export default function useListingCollection<ServerItem = any>({
  basedUrl,
  isActiveProperties,
  status,
  filter,
  currentPage,
  action,
  isChecked,
  initialItemsPerPage = 10,
}: Props) {
  const isLarge = useMedia('(min-width: 1640px)');
  const [page, setPage] = useState<number>(1);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [itemsToDisplay, setItemsToDisplay] =
    useState<ApiListing<ServerItem> | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchUrl, setSearchUrl] = useState<string>('');
  const [loadingItemsToDisplay, setLoadingItemsToDisplay] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [, setDataFilterToSend] = useState<Partial<FilterApiBody> | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const locale = i18n.language;

  useEffect(() => {
    handleClose();
    setSelectedRows([]);
    action != 'propertyDetails' && setSearchParams({});
  }, [isActiveProperties]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //MEouseEvent<HTMLElement>
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateApiUrl = (): string => {
    let url = '';
    switch (currentPage) {
      case 'promotionsStepper':
        url = basedUrl;
        break;
      case 'propertiesHistory':
        url = `${basedUrl}?page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'GeneratedBrochures':
        url = `${basedUrl}&page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'leads':
      case 'propertiesPublished':
      case 'leadsList':
      case 'offers':
        url = `${basedUrl}?page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'matchings':
        url = `${basedUrl}?page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'promotions':
      case 'properties':
      case 'portals':
      case 'contacts':
      case 'brochureTemplates':
      case 'pipelines':
        url = isActiveProperties
          ? `${basedUrl}?${
              status ? `status=${status}&` : ''
            }page=${page}&itemsPerPage=${itemsPerPage}`
          : `${basedUrl}/archived?page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'networks':
        url = `${basedUrl}?${
          status ? `status=${status}&` : ''
        }page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'networks-all':
        url = `${basedUrl}?${
          status ? `createdBy=${status}&` : ''
        }page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      case 'networks-agencies':
        url = `${basedUrl}?${status ? `startingLetter=${status}&` : ''}${
          filter ? `city=${filter}&` : ''
        }page=${page}&itemsPerPage=${itemsPerPage}`;
        break;
      default:
        break;
    }
    return url;
  };

  // Construct the URL based on the current page or parameters
  const apiUrl = generateApiUrl();

  const { data: onLoadPageData }: any = useSWR(
    !isFilterActive && itemsPerPage > 0 && basedUrl !== '' && apiUrl
  );

  useEffect(() => {
    !isFilterActive && setItemsToDisplay(onLoadPageData);
  }, [onLoadPageData]);

  useEffect(() => {
    if (!isFilterActive && itemsPerPage > 0) {
      mutate(apiUrl); // Manually trigger data fetching
    }
  }, [isFilterActive, itemsPerPage]);
  useEffect(() => {
    mutate(apiUrl);
  }, [isChecked]);

  useEffect(() => {
    setLoadingItemsToDisplay(true);
  }, [page, itemsPerPage, status, filter]);

  useEffect(() => {
    itemsToDisplay && setLoadingItemsToDisplay(false);
  }, [itemsToDisplay]);

  //function to archive properties
  const handleArchiveProperties = async () => {
    const ids = selectedRows.map(({ id }) => id);
    await http.patch(`${basedUrl}/archive`, {
      toArchive: ids,
    });
    mutate(`${basedUrl}?page=${page}&itemsPerPage=${itemsPerPage}`);
  };

  //function to restore properties
  const handleRestoreProperties = async () => {
    const ids = selectedRows.map(({ id }) => id);
    await http.patch(`${basedUrl}/unarchive`, {
      toArchive: ids,
    });
    mutate(`${basedUrl}/archived?page=${page}&itemsPerPage=${itemsPerPage}`);
  };

  const handleItemClick = (label: string) => {
    switch (label) {
      case 'archive':
        return handleArchiveProperties();
      case 'restore':
        return handleRestoreProperties();
      default:
        break;
    }
    handleClose();
  };

  // Generate the URL based on the current page
  const generateSearchUrl = (newSearchParams: string): string => {
    let url = '';
    switch (currentPage) {
      case 'contacts':
      case 'brochureTemplates':
      case 'pipelines':
        url = `${basedUrl}/filter?page=${page}&itemsPerPage=${itemsPerPage}&${newSearchParams}`;
        break;
      case 'properties':
      case 'promotions':
        url = `${basedUrl}/elastic?page=${page}&itemsPerPage=${itemsPerPage}&${newSearchParams}`;
        break;
      case 'leadsList':
        url = `/prospects/elastic?page=${page}&itemsPerPage=${itemsPerPage}&${newSearchParams}`;
        break;
      case 'promotionsStepper':
        url = `${basedUrl}&${newSearchParams}`;
        break;
      case 'propertiesHistory':
        url = `${basedUrl}?page=${page}&itemsPerPage=${itemsPerPage}&${newSearchParams}`;
        break;
      default:
        // Handle unknown pages, or set a default URL
        url = '/';
        break;
    }

    return url;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingItemsToDisplay(true);
      let url = '';
      if (currentPage === 'propertiesHistory') {
        //
        url = generateSearchUrl(searchUrl);
      } else {
        url = generateSearchUrl(searchParams.toString());
      }
      const res = await http.get(url);
      const { data } = res;
      setItemsToDisplay(data);
    };

    isFilterActive && fetchData();
  }, [page]);

  // make url into
  const getFiltredProperties = async (
    newdataTosend: Partial<FilterApiBody>
  ) => {
    const newSearchParams = new URLSearchParams();
    let url = '';
    for (const [key, value] of Object.entries(newdataTosend)) {
      if (Array.isArray(value)) {
        const jsonValue = JSON.stringify(value);
        newSearchParams.set(key, jsonValue);
      } else {
        newSearchParams.set(key, value as string);
      }
    }
    const searchString = newSearchParams.toString();
    if (currentPage === 'propertiesHistory') {
      setSearchUrl(searchString);
      url = generateSearchUrl(searchString);
    } else {
      setSearchParams(newSearchParams);
      url = generateSearchUrl(searchString);
    }
    setLoadingItemsToDisplay(true);
    const lang = locale.split('-')[0] || 'en';
    const newDatToSend: Partial<FilterApiBody> = { lang, ...newdataTosend };
    const res = await http.get(url);
    const { data } = res;
    setPage(1);
    setDataFilterToSend({ ...newDatToSend });
    setItemsToDisplay(data);
    setIsFilterActive(true);
  };

  const resetFilter = () => {
    if (currentPage === 'propertiesHistory') {
      setSearchUrl('');
    } else {
      setSearchParams({});
    }
    setIsFilterActive(false);

    setPage(1);
  };
  return {
    itemsToDisplay,
    resetFilter,
    handleItemClick,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    isLarge,
    isFilterActive,
    selectedRows,
    setSelectedRows,
    getFiltredProperties,
    anchorEl,
    handleClick,
    handleClose,
    open,
    page,
    loadingItemsToDisplay,
    setLoadingItemsToDisplay,
    mutate: () => mutate(apiUrl),
  };
}
