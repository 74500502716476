import classNames from 'classnames';
import { createElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EditorSidebarSection from '../sidebarSection';
import {
  usePagesEditorComputables,
  usePagesEditor,
} from '../../lib/hooks/usePagesEditor';
import { widgetTypes } from '../../lib/widgetTypes';
import { WidgetOptionValues } from '../../../../types/brochureEditor';

const WidgetSelection = ({ subjectType }: { subjectType: number }) => {
  const { assignWidgetToArea } = usePagesEditor();
  const {
    editedPageIsRepeatable,
    editedEntityId,
    editedWidget,
    isEditingLayout,
    editedAreaIdFull,
  } = usePagesEditorComputables();

  const allowedWidgetEntries = useMemo(() => {
    return Object.entries(widgetTypes).filter(
      ([, { placement, subjectType: widgetSubjectType }]) => {
        const isPlacementOk = isEditingLayout
          ? placement.layoutParts
          : editedPageIsRepeatable
          ? placement.repeatablePages
          : placement.pages;

        const isSubjectOk =
          widgetSubjectType === undefined || widgetSubjectType === subjectType;

        return isPlacementOk && isSubjectOk;
      }
    );
  }, [isEditingLayout, subjectType, editedPageIsRepeatable]);

  const { t } = useTranslation('brochureEditor');

  const [search, setSearch] = useState('');

  return (
    <>
      {editedEntityId && editedAreaIdFull && (
        <EditorSidebarSection>
          <div className="pt-4 grid gap-4">
            <div className="text-[14px] leading-[16px] font-medium">
              {t('select_widget')}
            </div>

            <input
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full rounded py-2 px-4 outline-none"
            />

            <div className="grid grid-cols-3 gap-2 text-center">
              {allowedWidgetEntries
                .filter(([, details]) => {
                  const originalName = details.name.toLowerCase();
                  const translatedName = t(originalName).toLowerCase();
                  const trimmedSearch = search.toLowerCase().trim();
                  return (
                    !search ||
                    originalName.includes(trimmedSearch) ||
                    translatedName.includes(trimmedSearch)
                  );
                })
                .map(([type, details]) => {
                  const isActive =
                    type === editedWidget?.type ||
                    (type === 'blank' && !editedWidget);

                  const defaultOptions: WidgetOptionValues = {};

                  for (const key in details.options) {
                    defaultOptions[key] = {
                      value: details.options[key]?.defaultValue || null,
                    };
                  }

                  return (
                    <button
                      key={type}
                      className={classNames(
                        'h-[108px] px-2 bg-white rounded flex-col justify-center items-center gap-2 inline-flex border cursor-pointer',
                        {
                          'border-transparent': !isActive,
                          'text-blue border-blue': isActive,
                        }
                      )}
                      onClick={() =>
                        assignWidgetToArea(editedEntityId, editedAreaIdFull, {
                          type,
                          options: defaultOptions,
                        })
                      }
                    >
                      <div className="w-6 h-6 relative">
                        {details.icon && createElement(details.icon)}
                      </div>
                      <div className="text-xs font-normal leading-3">
                        {t(details.name)}
                      </div>
                    </button>
                  );
                })}
            </div>
          </div>
        </EditorSidebarSection>
      )}
    </>
  );
};

export default WidgetSelection;
