import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PropertiesFilterHeader from './propertiesFilterHeader';
import PlusIcon from '../../../features/icons/plusIcon';
import SearchIcon from '../../../features/icons/searchIcon';
import { textFieldStyle } from '../../../const/propertiesFilter';
import useSWR, { mutate } from 'swr';
import useLocale from '../../../hooks/useLocale';
import CloseIcon from '../../../features/icons/closeIcon';
import { http } from '../../../libs/axios';
import { useNotification } from '../../../hooks/useNotification';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../../pages/properties/utils';

interface Props {
  children: ReactNode | ReactNode[];
  page: string;
  handleResetFilter: () => void;
  submitSearch: () => void;
  withSavedQueries?: boolean;
  submitSavedSearch?: (filterName: string) => void;
  getSingleSavedSearch?: (id: number) => void;
  setSearchQueryName?: (SavedQueryName: string) => void;
  searchQueryName?: string;
  isLoading?: boolean;
}

export const SubmitSearchStyle = {
  color: '#7c7c81',
  width: '100%',
  fontSize: '12px',
  lineHeight: '16px',
  alignSelf: 'end',
  padding: '12px 24px',
  backgroundColor: '#F5F5F7',
  '&:hover': {
    backgroundColor: '#E6E8EC',
  },
};

const FilterLayout = ({
  children,
  withSavedQueries = false,
  page,
  submitSavedSearch,
  getSingleSavedSearch,
  submitSearch,
  setSearchQueryName,
  searchQueryName,
  handleResetFilter,
  isLoading,
}: Props) => {
  const { addNotification } = useNotification();
  const { locale } = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [queryToSave, setQueryToSave] = useState(false);
  const [savedSearchErrors, setsavedSearchErrors] = useState(false);

  const setSavedQueriesUrl = () => {
    switch (page) {
      case 'property':
        return `/search_properties`;
      case 'promotion':
      case 'leads':
        return '';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (searchQueryName && searchQueryName.length !== 0) {
      setsavedSearchErrors(false);
      setQueryToSave(true);
    }
  }, [searchQueryName]);

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const onClick = () => {
    toggleCollapse();
  };

  const { data: savedSearchQueries } = useSWR(
    isOpen && withSavedQueries && `${setSavedQueriesUrl()}?lang=${locale}`
  );

  const handleSavedQueryName = (name: string) => {
    if (setSearchQueryName) {
      setSearchQueryName(name);
    }
  };

  const handleSubmitSavedSearch = () => {
    if (searchQueryName && searchQueryName.length === 0) {
      setsavedSearchErrors(true);
      return;
    }
    if (searchQueryName && submitSavedSearch) {
      submitSavedSearch(searchQueryName);
    }
  };

  const handleGetSingleSearch = (id: number) => {
    if (getSingleSavedSearch) getSingleSavedSearch(id);
  };
  const { t } = useTranslation('propertiesPage');
  const handleDeleteSavedSearch = async (id: number) => {
    try {
      await http.delete(`${setSavedQueriesUrl()}/${id}`);
      mutate(`${setSavedQueriesUrl()}?lang=${locale}`);
      addNotification(t('Search_query_has_been_successfully_deleted'));
      handleResetFilter();
    } catch {
      addNotification(t('error_on_delete_saved_search'));
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [window.location.href]);

  const getSavedQueries = () => {
    return (
      <Box>
        <Box
          className="flex justify-between items-center cursor-pointer saved_search"
          onClick={onClick}
        >
          <Typography fontSize="2rem">
            {' '}
            {t('Saved_search_requests')} ({savedSearchQueries?.length || 0})
          </Typography>
          {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {collapsed && (
          <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        )}
        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <Box component="ul" className="flex gap-4">
            {savedSearchQueries?.map(({ name, id }: any) => (
              <Box
                className="hover:cursor-pointer"
                sx={[
                  {
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    height: '40px',
                    background: '#F5F5F7',
                    color: '#6E6E72',
                    border: '1px solid #F5F5F7',
                    borderRadius: '4px',
                  },
                ]}
                key={name}
              >
                <Typography
                  fontWeight="500"
                  fontSize="1.2rem"
                  onClick={() => handleGetSingleSearch(id)}
                >
                  {name}
                </Typography>
                <div
                  onClick={() =>
                    confirmDelete(() => handleDeleteSavedSearch(id))
                  }
                >
                  <CloseIcon className="ml-3 w-6 h-6" color="red" />
                </div>
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box className="mb-5">
      <PropertiesFilterHeader
        setIsOpen={setIsOpen}
        handleResetFilter={handleResetFilter}
      />
      <Box
        sx={{ color: 'text.primary', background: 'white' }}
        className={`${
          isOpen ? 'h-full p-4 ' : 'max-h-0  '
        }  justify-between  overflow-hidden transition-all duration-500 ease-in`}
      >
        <Box
          className={`${
            isOpen ? ' visible' : 'invisible '
          } transition-opacity delay-500 grid items-center`}
        >
          {withSavedQueries && getSavedQueries()}
          {withSavedQueries && (
            <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
          )}
          {children}
        </Box>
        {withSavedQueries && (
          <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        )}
        {withSavedQueries && (
          <FormControlLabel
            control={
              <Checkbox
                className="save_search_request"
                checked={queryToSave}
                onChange={() => setQueryToSave(!queryToSave)}
                color="primary"
              />
            }
            label={t('Saved_search_requests')}
          />
        )}
        {withSavedQueries && queryToSave && (
          <Box className="w-2/6 mt-7">
            <TextField
              value={searchQueryName}
              className="!h-12 w-full input_save_search"
              sx={textFieldStyle}
              type="text"
              variant="outlined"
              helperText={savedSearchErrors && 'The name is required'}
              onChange={(e) => handleSavedQueryName(e.target.value)}
              error={savedSearchErrors}
            />
            <LoadingButton
              className={`flex gap-2 justify-center items-center !mt-10 !h-12 btn_save_search`}
              sx={SubmitSearchStyle}
              type="submit"
              onClick={handleSubmitSavedSearch}
              loading={isLoading}
              loadingPosition="start"
            >
              {!isLoading ? <PlusIcon color="#7c7c81" /> : <span></span>}
              <span
                title={t('Saved_search_requests')}
                className={
                  'block whitespace-nowrap overflow-hidden overflow-ellipsis'
                }
              >
                {t('Saved_search_requests')}
              </span>
            </LoadingButton>
          </Box>
        )}

        <Divider sx={{ margin: '20px 0', border: '1px solid #E6E8EC' }} />
        <Button
          className="!grid !grid-cols-3 h-9 col-span-1 w-full cursor-pointer"
          variant="outlined"
          sx={{
            width: '100%',
            height: '50px',
            border: '1px solid #3446B9 !important',
          }}
          onClick={() => {
            submitSearch();
            setTimeout(() => {
              setIsOpen(false);
            }, 1000);
          }}
        >
          <SearchIcon color="#3446B9" className="" />
          {page === 'stepperPromotion' ? (
            <span>{t('Apply')}</span>
          ) : (
            <span>{t('Search')}</span>
          )}
        </Button>
      </Box>
      {/* Other common layout elements */}
    </Box>
  );
};

export default FilterLayout;
