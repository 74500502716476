import { http } from '../libs/axios';
import { IReferencesElastic, ITitlesElastic } from '../types/filter';
import i18n from '../i18n';
import { debounce, IFilterProperties, searchCountries } from '../helpers/utils';
import {
  initialLeadsMainFilter,
  initialMainFilter,
  initialPropertiesHistoryMainFilter,
  selectFilterOptionT,
} from '../const/propertiesFilter';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { useFiltersProperties } from './useFiltersProperties';
import {
  initialPromotionMainFilter,
  initialStepperPromotionMainFilter,
} from '../const/mainPromotionFilter';
import { initialContactMainFilter } from '../const/mainContactsFilter';
import useSWR from 'swr';
import { AutocompleteOption } from '../components/form/autoCompleteField';
import { useAuth } from './useAuth';

type Props = {
  basedUrl: string;
  currentPage: string;
};

export default function usePropertyFilterStore({
  basedUrl,
  currentPage,
}: Props) {
  const { isAssistant, user } = useAuth();

  const [page, setPage] = useState<number>(1);

  const [itemsPerPage] = useState<number>(10);

  // get initial main filter for different pages
  const getInitialMainFilter = (currentPage: string) => {
    switch (currentPage) {
      case 'properties':
        return initialMainFilter;
      case 'promotions':
        return initialPromotionMainFilter;
      case 'contacts':
        return initialContactMainFilter;
      case 'promotionsStepper':
        return initialStepperPromotionMainFilter;
      case 'propertiesHistory':
        return initialPropertiesHistoryMainFilter;
      case 'leadsList':
        return initialLeadsMainFilter;
      default:
        break;
    }
  };

  function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setPage(page + 1);
        resolve();
      }, duration);
    });
  }

  const { data: users } = useSWR(
    currentPage === 'contacts' &&
      !isAssistant &&
      `/users/assigned?page=${page}&itemsPerPage=${itemsPerPage}`
  );
  // update agents option of agents field in contact filter depending on user role (assistant/user)
  useEffect(() => {
    if (currentPage === 'contacts') {
      let usersOptions: AutocompleteOption[] = [];
      if (isAssistant) {
        const { users }: any = user;
        usersOptions =
          users.length > 0
            ? users.map(
                (item: { id: any; firstname: string; lastname: string }) => {
                  return {
                    id: String(item.id),
                    value: `${item.firstname} ${item.lastname}`,
                  };
                }
              )
            : [];
      } else {
        usersOptions =
          users && users['hydra:member']
            ? users['hydra:member'].map(
                (item: { id: any; firstname: string; lastname: string }) => ({
                  id: item.id.toString(),
                  value: `${item.firstname} ${item.lastname}`,
                })
              )
            : [];
      }
      updateElementOptions('agents', usersOptions);
    }
  }, [users, currentPage]);

  const locale = i18n.language;
  const [mainFilterState, setMainFilterState] = useState(
    getInitialMainFilter(currentPage)
  );
  const { actionOptions, statusOptions, categoryOptions, departmentService } =
    useFiltersProperties(); //departmentService

  // update filter state
  const updateElementOptions = (filterLabel: string, newOptions: unknown[]) => {
    setMainFilterState((prevFilter: any) => {
      const updatedFilter = { ...prevFilter };

      // Find the element in the filter by iterating over the sections
      for (const section in updatedFilter) {
        updatedFilter[section] = updatedFilter[section]?.map((element: any) => {
          if (element.label === filterLabel) {
            return {
              ...element,
              disableField: false,
              options: newOptions,
            };
          }
          return element;
        });
      }

      return updatedFilter;
    });
  };

  const injectOptionsIntoState = (
    filter: { type: string; value: string },
    basicOptions: string[]
  ) => {
    const { type, value } = filter;
    const convertOptions: selectFilterOptionT[] = basicOptions?.map(
      (option: string) => {
        return {
          id: option,
          firstname: option,
          lastname: '',
          value: option,
          label: option,
        };
      }
    );

    //add what the user typed in the input
    const optionToDisplay: selectFilterOptionT[] = [
      {
        id: value,
        firstname: value,
        lastname: '',
        value: value,
        label: value,
      },
      ...convertOptions,
    ];

    const newOptions = uniqBy(optionToDisplay, function (e) {
      return e.id.toString().toLowerCase();
    });
    updateElementOptions(type, newOptions);
  };

  // get options for reference title and location input with debounce
  const handleSetSideLiseFilter = async (filter: {
    type: string;
    value: string;
  }) => {
    const { type, value } = filter;
    if (!value || value === '') return null;
    switch (type) {
      case 'reference':
      case 'propertyReferences':
      case 'promotionReferences':
        {
          const response: IReferencesElastic = await http.get(
            `${basedUrl}/elastic/references?reference=${value}`
          );
          const { data: dataReferences } = response;
          const { references } = dataReferences;

          injectOptionsIntoState(filter, references);
        }
        break;
      case 'title':
        {
          const response: ITitlesElastic = await http.get(
            `${basedUrl}/elastic/titles?title=${value}&lang=${locale}`
          );
          const { data: dataTitles } = response;
          const { titles } = dataTitles;
          const basicTitleOption: string[] = titles?.map(
            ({ title }): string => title
          );
          injectOptionsIntoState(filter, basicTitleOption);
        }
        break;
      case 'city':
        {
          const response = await http.get(
            `${basedUrl}/elastic/cities?city=${value}&lang=${locale}`
          );
          const { data: dataCities } = response;
          const { cities } = dataCities;
          injectOptionsIntoState(filter, cities);
        }
        break;
      case 'country':
        {
          const dataCountries: any = searchCountries(locale, value);
          injectOptionsIntoState(filter, dataCountries);
        }
        break;
      case 'zipCode':
        {
          const response = await http.get(
            `${basedUrl}/elastic/cp?zipCode=${value}`
          );

          const { data: zipCodeData } = response;
          const { zipcodes } = zipCodeData;

          injectOptionsIntoState(filter, zipcodes);
        }
        break;
      case 'adresse':
        {
          const encodedValue = encodeURIComponent(value);
          const response = await http.get(
            `${basedUrl}/elastic/addresses?address=${encodedValue}&lang=${locale}`
          );
          const { data: adressData } = response;
          const { addresses } = adressData;
          injectOptionsIntoState(filter, Object.values(addresses));
        }
        break;
      default:
        null;
    }
  };

  const debouncedGetFilterSearch = debounce(
    (input: IFilterProperties) => handleSetSideLiseFilter(input),
    300
  );
  const getInputValue: any = (value: string, label: string) => {
    debouncedGetFilterSearch({ type: label, value });
  };

  useEffect(() => {
    updateElementOptions('service', departmentService);
  }, [departmentService]);

  useEffect(() => {
    updateElementOptions('status', statusOptions);
  }, [statusOptions]);

  useEffect(() => {
    updateElementOptions('category', categoryOptions);
  }, [categoryOptions]);

  useEffect(() => {
    updateElementOptions('historicAction', actionOptions);
  }, [actionOptions]);

  return {
    sleep,
    getInputValue,
    updateElementOptions,
    mainFilterState,
  };
}
