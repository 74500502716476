export interface IReferences {
  references: string[];
}

export interface IReferencesElastic {
  data: IReferences;
}

export interface IGlobalAdressWithTranslation {
  [key: string]: TranslationAdress;
}

export interface TranslationAdress {
  country: string;
  district: string;
  city: string;
  zone: string;
  quarter: string;
  canton: string;
  adresse: string;
  locale: string;
}

export interface IAddress {
  translations: IGlobalAdressWithTranslation[];
  zipCode: string;
}

export interface TranslationInfos {
  name?: string | null;
  title?: string | null;
  locale: string | null;
}

export interface IAdditionalTranslatInfos {
  [key: string]: TranslationInfos;
}

export interface ILocations {
  locations: IAddress[];
}

export interface ILocationsElastic {
  data: ILocations;
}

export interface ITitle {
  title: string;
}

export interface Ititles {
  titles: ITitle[];
}

export interface ITitlesElastic {
  data: Ititles;
}

export interface FilterApiBody {
  lang: string;
  archive: boolean;
  reference?: string[];
  title?: string;
  zipCode?: string;
  city?: string;
  adresse?: string;
  country?: string;
  canton?: string;
  district?: string;
  agents?: number[];
  status?: number;
  category?: number;
  type?: number;
  availableFrom?: string;
  availableTo?: string;
  priceMin?: number;
  priceMax?: number;
  minRooms?: number;
  maxRooms?: number;
  minBedrooms?: number;
  maxBedrooms?: number;
  minBathrooms?: number;
  maxBathrooms?: number;
  minLivingSurface?: number;
  maxLivingSurface?: number;
  minLandSurface?: number;
  maxLandSurface?: number;
  parkings?: number;
  departments?: number[];
  service?: number;
}

export interface SavedSearchApiBody {
  reference?: string[];
  name?: string;
  agents?: number[];
  status?: number;
  category?: number;
  type?: number;
  availableFrom?: string;
  availableTo?: string;
  priceMin?: number;
  priceMax?: number;
  minRooms?: number;
  maxRooms?: number;
  minBedrooms?: number;
  maxBedrooms?: number;
  minBathrooms?: number;
  maxBathrooms?: number;
  minLivingSurface?: number;
  maxLivingSurface?: number;
  minLandSurface?: number;
  maxLandSurface?: number;
  parkings?: number;
  departments?: number[];
  service?: number;
  address?: IAddress;
  translations?: IAdditionalTranslatInfos;
}

export type initialFilterValuesI = {
  reference?: string[];
  name?: string;
  title?: string;
  city?: string;
  zipCode?: string;
  adresse?: string;
  agents?: number;
  status?: string;
  category?: string;
  type?: string;
  availableFrom?: string;
  availableTo?: string;
  priceMin?: number | null;
  priceMax?: number | null;
  minRooms?: number | null;
  maxRooms?: number | null;
  minBedrooms?: number | null;
  maxBedrooms?: number | null;
  minBathrooms?: number | null;
  maxBathrooms?: number | null;
  minLivingSurface?: number | null;
  maxLivingSurface?: number | null;
  minLandSurface?: number | null;
  maxLandSurface?: number | null;
  parkings?: number | null;
  departments?: string;
  service?: string;
  address?: IAddress;
  country?: string;
  canton?: string;
  district?: string;
};

export const initialFilterValues = {
  reference: [],
  title: '',
  city: '',
  zipCode: '',
  adresse: '',
  agents: null,
  status: '',
  category: '',
  type: '',
  availableFrom: '',
  availableTo: '',
  priceMin: null,
  priceMax: null,
  minRooms: null,
  maxRooms: null,
  minBedrooms: null,
  maxBedrooms: null,
  minBathrooms: null,
  maxBathrooms: null,
  minLivingSurface: null,
  maxLivingSurface: null,
  minLandSurface: null,
  maxLandSurface: null,
  parkings: null,
  departments: [],
  service: '',
  address: null,
  country: '',
  canton: '',
  district: '',
};

export type initialPropertiesHistoryFilterValuesI = {
  historicDateFrom?: string;
  historicDateTo?: string;
  doneBy?: number[] | null;
  departments: string[];
};
export const initialPropertiesHistoryFilterValues: initialPropertiesHistoryFilterValuesI =
  {
    historicDateFrom: '',
    historicDateTo: '',
    doneBy: null,
    departments: [],
  };

export type initialLeadsFilterValuesI = {
  propertyReferences?: string[];
  promotionReferences?: string[];
  status?: string;
  startDate?: string;
  endDate?: string;
  departments?: string[];
  source: string;
  clientName: string;
  service?: string;
  agents?: number | null;
  isProperty?: boolean | null;
  isPromotion?: boolean | null;
};
export const initialLeadsFilterValues: initialLeadsFilterValuesI = {
  propertyReferences: [],
  promotionReferences: [],
  status: '',
  startDate: '',
  endDate: '',
  departments: [],
  source: '',
  clientName: '',
  service: '',
  agents: null,
  isProperty: null,
  isPromotion: null,
};
