import { generateProps as generateDescriptionProps } from '../components/widgets/universal/multipageDescription';
import { generateProps as generateGalleryProps } from '../components/widgets/universal/multipageGallery';
import { generateProps as generateAreasProps } from '../components/widgets/property/areas';
import { generateProps as generateConveniencesProps } from '../components/widgets/property/multipageConveniences';
import { generateProps as generateDistancesProps } from '../components/widgets/property/multipageDistances';

export function pickMultipagePropsGenerator(widgetType?: string) {
  if (widgetType === 'multipageGallery') {
    return generateGalleryProps;
  }
  if (widgetType === 'multipageDescription') {
    return generateDescriptionProps;
  }
  if (widgetType === 'areas') {
    return generateAreasProps;
  }
  if (widgetType === 'multipageConveniences') {
    return generateConveniencesProps;
  }
  if (widgetType === 'multipageDistances') {
    return generateDistancesProps;
  }
  return null;
}
