import useLocale from '../../../../hooks/useLocale';
import { Box, Button, Fade, Modal } from '@mui/material';
import { IPropertyApi } from '../../../../types/property';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { convertDisplayedPrice } from '../../../properties/utils';
import HomeIcon from '../../../../features/icons/homeIcon';
import BedroomIcon from '../../../../features/icons/bedroomIcon';
import SquareIcon from '../../../../features/icons/squareIcon';
import MountainIcon from '../../../../features/icons/mountainIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Input from '@mui/material/Input';
import { TextareaAutosize } from '@mui/base';
import { Controller, useForm } from 'react-hook-form';
import { useNotification } from '../../../../hooks/useNotification';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import CheckIcon from '@mui/icons-material/Check';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropertyFirstImage from '../../../../components/tableGridX/propertyFirstImage';
import Checkbox from '@mui/material/Checkbox';
import classNames from 'classnames';
import { IContactGreeting } from '../../../../types/contacts';
import useSWR from 'swr';
import { ISettingEmails } from '../../../../types/settings';
import { getMatchingSettingByType } from '../../../../helpers/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mutate?: any;
  contactName: string;
  contactId: string | number;
  items: IPropertyApi[];
  onEmailSent: () => void;
  onClickAway: () => void;
  greeting: IContactGreeting | null;
};

export function getDefaultEmailValues(locale: string, data: ISettingEmails) {
  if (data && data.translations && data.translations[locale]) {
    return {
      subject: data.translations[locale]?.subject,
      text: data.translations[locale]?.text1,
      secondText: data.translations[locale]?.text2,
    };
  }
}

const SendEmailModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    contactName,
    greeting,
    contactId,
    items,
    mutate,
    onEmailSent,
  } = props;
  const { locale } = useLocale();
  const { handleSubmit, register, reset, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [withGreeting, setWithGreeting] = useState(false);
  const [sent, setSent] = useState(false);
  const { addNotification } = useNotification();
  const { data: settings } = useSWR<ISettingEmails[]>('/setting_emails');
  const currentSettings = getMatchingSettingByType('1', settings);
  const { t } = useTranslation('common');
  useEffect(() => {
    if (currentSettings) {
      reset(getDefaultEmailValues(locale, currentSettings));
    }
  }, [currentSettings]);

  const onSubmit = (data: any) => {
    const body = {
      displayGreeting: withGreeting,
      subject: data.subject,
      text1: data.text,
      text2: data.secondText,
      properties: items.map((el) => el.id),
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    const promise: Promise<AxiosResponse> = http.post(
      `/contacts/${contactId}/send_matching`,
      body
    );

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (mutate) {
          mutate(data);
        }
        reset();
        setSent(true);
        addNotification(t('Email_has_been_sent'), 'success');
        onEmailSent();
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = t('Failed_to_connect_to_server');
        } else {
          message = t('Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  const onClickAway = () => {
    isOpen && onClose();
    if (!sent) {
      return false;
    }
    setTimeout(() => {
      setSent(false);
      if (props.onClickAway) {
        props.onClickAway();
      }
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <Box
      sx={{
        '& .MuiBox-root': {
          padding: '0 !important',
        },
      }}
    >
      <Modal open={isOpen} onClose={onClose}>
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade in={isOpen}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '95vh',
                // overflowY: 'auto',
                width: {
                  xs: '90%',
                  md: 600,
                },
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 24,
              }}
            >
              <form
                className={classNames('relative', {
                  'flex flex-col': sent,
                })}
                onSubmit={handleSubmit(onSubmit)}
              >
                {isLoading ? (
                  <div className={'absolute inset-0 z-10'}>
                    <SpinnerLoading />
                  </div>
                ) : null}
                {sent ? (
                  <div className="w-full px-8 py-16 flex-col justify-start items-center gap-8 inline-flex">
                    <div className="text-[#4CAF50] p-4 rounded-[200px] border-2 border-green-500 justify-start items-center gap-2 inline-flex">
                      <CheckIcon
                        sx={{
                          width: '32px',
                          height: '32px',
                        }}
                      />
                    </div>
                    <div className="self-stretch text-center text-zinc-900 2xl:text-2xl font-normal font-['Inter'] leading-7">
                      {t('Email_to')} {contactName}
                      <br />
                      {t('has_been_sent')}
                    </div>
                  </div>
                ) : (
                  <div className={'flex flex-col'}>
                    <div className="w-full p-6 bg-gray-50 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
                      <div className="grow shrink basis-0 text-zinc-900 2xl:text-2xl font-normal font-['Inter'] leading-7">
                        {t('New_email_to')} {contactName}
                      </div>
                    </div>

                    <div
                      className={'overflow-y-auto max-h-[calc(100vh-220px)]'}
                    >
                      <div
                        className={classNames(
                          'w-full self-stretch px-8 py-2 border-b border-gray-200 justify-start items-start gap-2 inline-flex',
                          {
                            'opacity-50 user-select-none': !greeting,
                          }
                        )}
                      >
                        <Controller
                          control={control}
                          name={'greeting'}
                          render={({ field }) => (
                            <label
                              className={classNames(
                                'flex items-center ml-[-16px]',
                                {
                                  'cursor-pointer': greeting,
                                }
                              )}
                            >
                              <Checkbox
                                className="private_checkbox"
                                disabled={!greeting}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setWithGreeting(!withGreeting);
                                }}
                                defaultChecked={!!field.value}
                              />
                              <span className={'text-xs 2xl:text-[14px]'}>
                                {t('Activate_Greeting')}
                              </span>
                            </label>
                          )}
                        />
                      </div>
                      <div className="w-full self-stretch p-6 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
                        <div className="w-1/5 2xl:w-2/6 text-zinc-900 text-sm 2xl:text-base font-normal leading-tight">
                          {t('Subject')}
                        </div>
                        <div className="grow shrink basis-0 text-zinc-900 text-base font-normal leading-tight">
                          <Input
                            {...register('subject')}
                            sx={{
                              '&': {
                                width: '100%',
                              },
                              '&:after, &:before': {
                                display: 'none',
                              },
                            }}
                            placeholder={t('Click_to_add_subject')}
                          />
                        </div>
                      </div>
                      <div className="w-full self-stretch px-8 pt-6 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
                        <div className="w-1/5 2xl:w-2/6 text-zinc-900 text-sm 2xl:text-base font-normal font-['Inter'] leading-tight">
                          {t('Text')}
                        </div>
                        <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
                          {withGreeting ? (
                            <p className={'opacity-80 mb-1'}>
                              {greeting?.translations?.[locale]?.name},
                            </p>
                          ) : null}
                          <TextareaAutosize
                            {...register('text')}
                            placeholder={t('Click_to_add_text')}
                            minRows={4}
                            maxRows={6}
                            className={'outline-0 w-full'}
                          />
                        </div>
                      </div>
                      <div className={'w-full px-8 flex flex-col divide-y'}>
                        {items.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="pt-6 pb-8 self-stretch justify-start items-start inline-flex"
                            >
                              <div className="relative w-1/5 2xl:w-2/6 shrink-0">
                                <PropertyFirstImage
                                  fullScalePicture
                                  row={item}
                                />
                              </div>
                              <div className="grow px-4 flex-col justify-start items-start gap-4 2xl:gap-8 inline-flex">
                                <div className="self-stretch flex-col justify-start items-start gap-0.5 2xl:gap-2 flex">
                                  <div
                                    className="flex py-1 px-2 rounded-md items-center"
                                    style={{ border: '1px solid #3446B9 ' }}
                                  >
                                    <PermissionIcon
                                      className="col-span-1"
                                      color="#3446B9"
                                    />{' '}
                                    <span className="text-[12px] 2xl:text-base text-blue leading-5">
                                      {item.reference}
                                    </span>
                                  </div>
                                  {item.translations?.[locale]?.title ? (
                                    <Link
                                      className={
                                        "text-blue-800 2xl:text-2xl font-normal font-['Inter'] leading-7"
                                      }
                                      to={`/properties/${item.id}`}
                                      id={`img_property_${item.id}`}
                                    >
                                      <p className={'font-medium'}>
                                        {item.translations?.[locale]?.title}
                                      </p>
                                    </Link>
                                  ) : null}
                                  <div className="text-zinc-900 text-[12px] 2xl:text-base font-semibold font-['Inter'] leading-tight">
                                    {item.displayedPrice
                                      ? `CHF ${
                                          convertDisplayedPrice(
                                            item?.displayedPrice?.toString()
                                          ) + '.-'
                                        }`
                                      : ''}
                                  </div>
                                </div>
                                <div className="flex-col justify-center items-start gap-0.5 2xl:gap-2 flex">
                                  <div className="justify-start items-center gap-y-2 gap-x-4 2xl:gap-x-8 inline-flex">
                                    <div className="justify-start items-center gap-2.5 flex">
                                      <HomeIcon
                                        className="col-span-1"
                                        color="#6E6E72"
                                      />
                                      <div className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                        {item.rooms}
                                      </div>
                                    </div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                      <SquareIcon
                                        className="col-span-1"
                                        color="#6E6E72"
                                      />{' '}
                                      <div className="">
                                        {item.livingArea ? (
                                          <>
                                            <span className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                              {(item.addFromToLivingArea
                                                ? t('propertiesPage:from') + ' '
                                                : '') +
                                                (item.useTildeForSurface
                                                  ? '~'
                                                  : '') +
                                                item.livingArea}{' '}
                                              m
                                            </span>
                                            <sup className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                              2
                                            </sup>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="justify-center items-center gap-y-2 gap-x-4 2xl:gap-x-8 inline-flex">
                                    <div className="justify-start items-center gap-2.5 flex">
                                      <BedroomIcon
                                        className="col-span-1"
                                        color="#6E6E72"
                                      />{' '}
                                      <div className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                        {item.bedrooms}
                                      </div>
                                    </div>
                                    <div className="justify-start items-center gap-2.5 flex">
                                      <MountainIcon
                                        className="col-span-1"
                                        color="#6E6E72"
                                      />
                                      <div className="">
                                        {item.landSurface ? (
                                          <>
                                            <span className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                              {(item.addFromToLandSurface
                                                ? t('propertiesPage:from') + ' '
                                                : '') +
                                                (item.useTildeForSurface
                                                  ? '~'
                                                  : '') +
                                                item.landSurface}{' '}
                                              m
                                            </span>
                                            <sup className="text-zinc-900 text-xs 2xl:text-sm font-normal font-['Inter'] leading-none">
                                              2
                                            </sup>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-full self-stretch px-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
                        <div className="w-1/5 2xl:w-2/6 text-zinc-900 text-sm 2xl:text-base font-normal font-['Inter'] leading-tight">
                          {t('Another_text')}
                        </div>
                        <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
                          <TextareaAutosize
                            {...register('secondText')}
                            placeholder={t('Click_to_add_text')}
                            minRows={4}
                            maxRows={6}
                            className={'outline-0 w-full'}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        'flex justify-between items-center px-8 py-5 border-t'
                      }
                    >
                      <Button
                        className="cursor-pointer flex justify-between items-center"
                        variant="outlined"
                        sx={{
                          height: '50px',
                          border: '1px solid #1D1D1F !important',
                          color: '#1D1D1F',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          onClose();
                        }}
                      >
                        <span className={'mt-1'}> {t('cancel')} </span>
                      </Button>
                      <Button
                        className="cursor-pointer gap-6"
                        type={'submit'}
                        variant={'contained'}
                        disableElevation
                        sx={{
                          height: '50px',
                          border: '1px solid #3446B9 !important',
                        }}
                      >
                        <span> {t('Send')} </span>
                        <ArrowForwardIosIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                          }}
                          fontSize={'small'}
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </Box>
          </Fade>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};

export default SendEmailModal;
