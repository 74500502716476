import { useTranslation } from 'react-i18next';
import {
  MultipagePropsGenerator,
  PreviewSubjectTypes,
  PropertyWidgetPreviewProps,
} from '../../../../../types/brochureEditor';
import { IPropertyApi } from '../../../../../types/property';
import { IConvenienceChoiceApi } from '../../../../../types/conveniences';
import useLocale from '../../../../../hooks/useLocale';
import WidgetTitle from '../../widgetTitle';

interface AttributeGroup {
  name: string;
  choices?: IConvenienceChoiceApi[];
}

const MultipageConveniencesWidget = ({
  multipageProps = {},
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const props = multipageProps as {
    groups?: AttributeGroup[];
    index: number;
  };
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  return (
    <div className="flex flex-col gap-4">
      <WidgetTitle
        title={t('common:Conveniences')}
        widgetOptions={widgetOptions}
        pageIndex={props?.index}
      />
      <div className="flex flex-col gap-6">
        {props?.groups
          ?.filter((group) => group.choices)
          .map(({ name, choices }, index) => (
            <div key={index} className="flex flex-col gap-2">
              <h3
                className="text-lg font-semibold uppercase border-b border-gray-200"
                style={{ color: 'var(--brand-color)' }}
              >
                {t(name)}
              </h3>
              <ul className="grid grid-cols-2">
                {choices?.map((item, index) => (
                  <li key={index}>
                    <span className="text-sm font-semibold">
                      {item.translations?.[locale]?.name || item.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultipageConveniencesWidget;

const PAGE_HEIGHT = 1000;

export const generateProps: MultipagePropsGenerator = async ({
  originalSubject,
  type,
}) => {
  if (!originalSubject || type !== PreviewSubjectTypes.PROPERTY) {
    return null;
  }
  const property = originalSubject as IPropertyApi;
  const { conveniences } = property;
  if (!conveniences) {
    return null;
  }

  const allGroups = [
    { name: 'Environment', choices: conveniences.environment },
    { name: 'Transports', choices: conveniences.transports },
    { name: 'Stores', choices: conveniences.stores },
    { name: 'Children_and_schooling', choices: conveniences.children },
    { name: 'Sport', choices: conveniences.sport },
    {
      name: 'Leisure_and_point_of_interest',
      choices: conveniences.leisure,
    },
    { name: 'rooms', choices: conveniences.rooms },
    {
      name: 'Outside_conveniences',
      choices: conveniences['outside environment'],
    },
  ].filter((g) => g.choices?.length);

  const pages = [];
  let totalPageHeight = 0;
  let thisPageGroups: AttributeGroup[] = [];
  let thisPageIndex = 0;
  for (const group of allGroups) {
    const groupHeight = calculateGroupHeight(group);
    if (
      totalPageHeight !== 0 &&
      totalPageHeight + groupHeight > PAGE_HEIGHT + 24
    ) {
      pages.push({
        groups: thisPageGroups,
        index: thisPageIndex,
      });
      thisPageGroups = [];
      totalPageHeight = 0;
      thisPageIndex++;
    }
    thisPageGroups.push(group);
    totalPageHeight += groupHeight;
  }
  pages.push({
    groups: thisPageGroups,
    index: thisPageIndex,
  });

  return pages;
};

function calculateGroupHeight(group: AttributeGroup) {
  const titleHeight = 38;
  const lineHeight = 24;
  const marginAfter = 24;
  const choices = group.choices || [];
  const numLines = Math.ceil(choices?.length / 2);
  return titleHeight + numLines * lineHeight + marginAfter;
}
