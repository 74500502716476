import * as React from 'react';
import useSWR from 'swr';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArticleIcon from '@mui/icons-material/Article';
import { useTranslation } from 'react-i18next';

interface Props {
  data: any;
  id: string;
  mutate?: any;
  idxFileContent?: string;
}

const IDXFileTab = (props: Props) => {
  const { id, idxFileContent } = props;
  const { t } = useTranslation();
  const {
    data: file,
    error,
    isValidating,
  } = useSWR(`/setting_portals/${id}/idx_file`);

  return (
    <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
      <div className="self-stretch text-neutral-500 text-xs font-normal font-['Inter'] leading-none">
        {t('common:idx-file')}
      </div>
      <div className="self-stretch grow shrink basis-0 p-4 bg-white rounded border border-gray-200 justify-start items-start gap-2.5">
        <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
          <div className={'flex items-center justify-center min-h-[500px]'}>
            {isValidating ? (
              <SpinnerLoading />
            ) : (
              <>
                {file ? (
                  <div
                    style={{
                      wordWrap: 'break-word',
                      width: '-webkit-fill-available',
                    }}
                  >
                    {idxFileContent != ''
                      ? idxFileContent
                      : file.idxFileContent}
                  </div>
                ) : (
                  <div
                    className={
                      'text-center opacity-50 flex flex-col items-center gap-2'
                    }
                  >
                    {error ? (
                      <>
                        <ErrorOutlineIcon />
                        {t('common:somethingWentWrong')}!
                        <br />
                        {t('common:Please_try_to_download_IDX_file_later')}.
                      </>
                    ) : (
                      <>
                        <ArticleIcon />
                        {t('common:IDX_file_is_empty')}.
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IDXFileTab;
