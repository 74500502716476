import { FC, createElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  usePagesEditorComputables,
  usePagesEditor,
} from '../../lib/hooks/usePagesEditor';
import { usePreviewSubject } from '../../lib/hooks/usePreviewSubject';
import useLocale from '../../../../hooks/useLocale';

import {
  EditorControlProps,
  PreviewSubjectTypes,
} from '../../../../types/brochureEditor';
import { IPromotionApi } from '../../../../types/promotion';
import { IPropertyApi } from '../../../../types/property';

import EditorSidebarSection from '../sidebarSection';
import BooleanControl from '../controls/boolean';

const WidgetOptionValues = () => {
  const { setWidgetOption, overrides } = usePagesEditor();
  const {
    editedWidget,
    editedWidgetDetails,
    editedEntityId,
    editedAreaIdFull,
  } = usePagesEditorComputables();
  const { type, originalSubject, previewSubject } = usePreviewSubject();

  const subjectOverrides = overrides[previewSubject?.id || ''];

  const { t } = useTranslation('brochureEditor');

  const { locale } = useLocale();

  return (
    <>
      {editedEntityId &&
        editedAreaIdFull &&
        editedWidget &&
        editedWidget.options &&
        editedWidgetDetails && (
          <EditorSidebarSection>
            <div className="pt-4 grid gap-4">
              <div className="text-[14px] leading-[16px] font-medium">
                {t(editedWidgetDetails.name)}
              </div>

              <div className="grid gap-4">
                {Object.entries(editedWidget.options).map(
                  ([key, { value: templateValue }]) => {
                    const optionDetails = editedWidgetDetails?.options?.[key];
                    if (!optionDetails) return null;

                    if (templateValue === undefined) {
                      templateValue = optionDetails.defaultValue;
                    }

                    const {
                      name,
                      control,
                      choices,
                      getChoices,
                      onlyForPreview = false,
                    } = optionDetails;

                    if (onlyForPreview && !previewSubject) {
                      return null;
                    }

                    // attempt to get value from overrides, and use it in place of common value
                    const override =
                      subjectOverrides?.[editedEntityId]?.widgets?.[
                        editedAreaIdFull
                      ]?.options?.[key]?.value;
                    const value =
                      typeof override === 'string' ||
                      typeof override === 'boolean'
                        ? override
                        : templateValue;

                    let finalChoices = choices;
                    if (getChoices) {
                      finalChoices = getChoices(
                        {
                          property:
                            type === PreviewSubjectTypes.PROPERTY
                              ? (originalSubject as IPropertyApi)
                              : undefined,
                          promotion:
                            type === PreviewSubjectTypes.PROMOTION
                              ? (originalSubject as IPromotionApi)
                              : undefined,
                        },
                        locale
                      );
                    }

                    if (control === BooleanControl) {
                      return (
                        <div key={key}>
                          {createElement(
                            control as FC<EditorControlProps<boolean>>,
                            {
                              value: value as boolean,
                              label: t(name),
                              onChange: (value: boolean) =>
                                setWidgetOption(
                                  editedEntityId,
                                  editedAreaIdFull,
                                  key,
                                  value,
                                  previewSubject?.id
                                ),
                              previewSubject,
                            }
                          )}
                        </div>
                      );
                    }
                    return (
                      <div key={key}>
                        {createElement(
                          control as FC<EditorControlProps<string>>,
                          {
                            value: value as string,
                            label: t(name),
                            choices: finalChoices,
                            onChange: (value: string) =>
                              setWidgetOption(
                                editedEntityId,
                                editedAreaIdFull,
                                key,
                                value,
                                previewSubject?.id
                              ),
                            previewSubject,
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </EditorSidebarSection>
        )}
    </>
  );
};
export default WidgetOptionValues;
