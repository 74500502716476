import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { IContact, IContactHobby } from '../../../../types/contacts';
import NoEditModeFormWrapper from './wrapper';
import { MenuItem, Select, Switch, TextField } from '@mui/material';
import { useNotification } from '../../../../hooks/useNotification';
import { http } from '../../../../libs/axios';
import { selectOptionStringT } from '../../../../components/form/selectFormField';
import InputLayout from '../../../../components/form/components/inputLayout';
import { AccordionView } from '../../creator/individual-view';
import Rating from '@mui/material/Rating';
import StarIcon from '../../../../features/icons/starIcon';
import { checkType, switchStyles } from './contact-type-form';
import { ContactTapInnerProps } from '../../singleContactPage/tabs/additional-information-tab';
import useSWR from 'swr';
import classNames from 'classnames';
import { getOptionsByLanguage } from '../../../../const/propertiesOptions';
import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteOption } from '../../../../components/form/autoCompleteField';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import useLocale from '../../../../hooks/useLocale';
import InputFormField from '../../../../components/form/inputFormField';

import { Translations } from '../../../../types/translations';
import WarningIcon from '../../../../features/icons/warningIcon';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

interface InitialValues {
  rating: number | null;
  portal: boolean;
  sourcePortals: number[] | null;
  sourcePropertyLP: boolean;
  sourcePromotionLP: boolean;
  externalBroker: boolean;
  sourceBroker: AutocompleteOption | null;
  website: boolean;
  direct: boolean;
  previewPage: boolean;
  ownerQualification: number | null;
  clientQualification: number | null;
  publication: number[] | null;
  visibility: number;
  hobbies: number[];
  warningField: {
    translations: Translations;
  } | null;
}

const transformDataForFormUsage = (data: IContact, hobbies: number[]) => {
  const initialValue: InitialValues = {
    rating: data.rating,
    portal: !!data.sourcePortals.length,
    sourcePortals: data.sourcePortals
      ? data.sourcePortals.map((el) => el.id)
      : [],
    externalBroker: !!data.sourceBroker,
    sourcePropertyLP: !!data.sourcePropertyLP,
    sourcePromotionLP: !!data.sourcePromotionLP,
    sourceBroker: data.sourceBroker
      ? {
          id: data.sourceBroker.id.toString(),
          label: data.sourceBroker.individual
            ? `${data.sourceBroker.individual?.firstname || ''} ${
                data.sourceBroker.individual?.lastname || ''
              }`
            : data.sourceBroker.company?.legalName || '',
        }
      : null,
    website: data.sourceWebsite,
    direct: data.sourceDirect,
    previewPage: data.sourcePreviewPage,
    ownerQualification: data.ownerQualifChoice
      ? +data.ownerQualifChoice.id
      : null,
    clientQualification: data.clientQualifChoice
      ? +data.clientQualifChoice.id
      : null,
    publication:
      data.publication && data.publication.visibilities
        ? data.publication.visibilities.map((el) => el.id)
        : [],
    visibility: +data.visibility?.id,
    hobbies,
    warningField: data.warningField,
  };
  return initialValue;
};

type Props = ContactTapInnerProps & {
  selectedHobbyIds: number[];
  mutateHobbies: () => unknown;
};

const AdditionalInformationForm = (props: Props) => {
  const { data, id, mutate, mutateHobbies, selectedHobbyIds } = props;

  const defaultValues = transformDataForFormUsage(data, selectedHobbyIds);

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isDirty },
  } = useProtectedForm<InitialValues>({ defaultValues }, data);
  const { locale } = useLocale();
  const watchAll = watch();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { data: portalsChoices } = useSWR('/portals');
  const { data: visibilitiesChoices } = useSWR('/visibilities');
  const { data: ownerQualifications } = useSWR('/owner_qualif_choices');
  const { data: clientQualifications } = useSWR('/client_qualif_choices');
  const { data: externalBrokers } = useSWR('/contacts/external-brokers');
  const { data: types } = useSWR(`/contact_types`);

  const ownerType = checkType(types, data, 'owner');
  const clientType = checkType(types, data, 'client');
  const { t } = useTranslation('common');
  const visibilitiesOptions = getOptionsByLanguage(
    visibilitiesChoices ? visibilitiesChoices : [],
    locale
  );
  const ownerQualificationChoices = getOptionsByLanguage(
    ownerQualifications ? ownerQualifications : [],
    locale
  );
  const clientQualificationChoices = getOptionsByLanguage(
    clientQualifications ? clientQualifications : [],
    locale
  );
  const externalBrokersChoices: AutocompleteOption[] = externalBrokers
    ? externalBrokers.map((item: IContact) => ({
        id: item.id,
        label: item.individual
          ? `${item.individual?.firstname} ${item.individual?.lastname}`
          : item.company?.legalName || '',
      }))
    : [];

  useEffect(() => {
    if (!watchAll['portal']) {
      resetField('sourcePortals', {
        defaultValue: [],
      });
    }
    if (!watchAll['externalBroker']) {
      resetField('sourceBroker', {
        defaultValue: null,
      });
    }
  }, [watchAll['portal'], watchAll['externalBroker']]);

  useEffect(() => {
    if (!ownerType) {
      resetField('ownerQualification', {
        defaultValue: data.ownerQualifChoice?.id,
      });
    }
    if (!clientType) {
      resetField('clientQualification', {
        defaultValue: data.clientQualifChoice?.id,
      });
    }
  }, [ownerType, clientType]);

  const onSubmit = (data: any) => {
    const existingTranslations = defaultValues.warningField?.translations || {};

    const updatedTranslations = {
      ...existingTranslations,
      [locale]: {
        locale: locale,
        value: data.warningField.translations[locale].value,
      },
    };
    const body = {
      rating: data.rating ? parseInt(data.rating) : null,
      sourceWebsite: data.website,
      sourceDirect: data.direct,
      sourcePortals: data.sourcePortals,
      sourcePreviewPage: data.previewPage,
      sourcePromotionLP: data.sourcePromotionLP,
      sourcePropertyLP: data.sourcePropertyLP,
      sourceBroker: data.sourceBroker ? data.sourceBroker.id : null,
      publication: {
        visibilities: data.publication,
      },
      visibility: data.visibility,
      ownerQualifChoice: data.ownerQualification,
      clientQualifChoice: data.clientQualification,
      warningField: {
        translations: updatedTranslations,
      },
    };

    submitDataToServer(body);
    saveHobbies(data.hobbies);
  };

  const submitDataToServer = async (body: any) => {
    const promise: Promise<AxiosResponse> = http.patch(`/contacts/${id}`, body);

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (mutate) {
          mutate(data);
        }
        addNotification(
          t('Your_contact_has_been_successfully_updated'),
          'success'
        );
        updateHistoric(id, 'Additional information has been updated');
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = t('Failed_to_connect_to_server');
        } else {
          message = t('Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  const { data: hobbies } = useSWR<IContactHobby[]>('/hobbies');

  async function saveHobbies(hobbies: number[]) {
    await http.post(`/contact_hobbies/${id}`, { hobbies });
    mutateHobbies();
  }

  const warningDefaultText = (defaultValues: any) => {
    return defaultValues?.warningField?.translations?.[locale]
      ? defaultValues?.warningField?.translations?.[locale].value
      : '';
  };

  return (
    <NoEditModeFormWrapper
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      buttonDisabled={!isDirty}
    >
      <>
        <AccordionView
          defaultExpanded
          title={t('Lead_source')}
          titleClassName={'text-[24px]'}
          className={'w-full col-start-1 col-end-4'}
        >
          <div className={'flex flex-wrap gap-10'}>
            <div className="w-[65%] grid gap-8">
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <div className="flex items-center justify-between border-b py-4 mb-4">
                    <p> {t('Portal')} </p>
                    <Controller
                      name={'portal'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Switch
                            className="portal_btn"
                            sx={switchStyles}
                            value={field.value}
                            defaultChecked={!!field.value}
                            onChange={(event, val) => field.onChange(val)}
                          />
                        );
                      }}
                    />
                  </div>
                  <InputLayout
                    className={classNames('', {
                      'opacity-50 pointer-events-none': !watchAll['portal'],
                    })}
                    label={t('Select_portals')}
                  >
                    <Controller
                      name="sourcePortals"
                      control={control}
                      render={({ field }) => (
                        <Select
                          disabled={!watchAll['portal']}
                          className={'h-[56px] select_portals_contact'}
                          labelId="level-label"
                          multiple
                          {...field}
                        >
                          {portalsChoices &&
                            portalsChoices.map(
                              (
                                item: { id: number; name: string },
                                index: number
                              ) => (
                                <MenuItem
                                  key={index}
                                  value={item?.id}
                                  className={`select_portals_${item.id}`}
                                >
                                  {item.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      )}
                    />
                  </InputLayout>
                </div>
                <div>
                  <div className="flex items-center justify-between border-b py-4 mb-4">
                    <p> {t('External_broker')} </p>
                    <Controller
                      name={'externalBroker'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Switch
                            className="external_broker_btn"
                            sx={switchStyles}
                            value={field.value}
                            defaultChecked={!!field.value}
                            onChange={(event, val) => field.onChange(val)}
                          />
                        );
                      }}
                    />
                  </div>
                  <InputLayout
                    className={classNames('', {
                      'opacity-50 pointer-events-none':
                        !watchAll['externalBroker'],
                    })}
                    label={t('Select_broker')}
                  >
                    <Controller
                      control={control}
                      name="sourceBroker"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value}
                          options={externalBrokersChoices}
                          renderInput={(params) => (
                            <TextField
                              className="select_broker_contact"
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </InputLayout>
                </div>
              </div>
              <div key={locale}>
                <div className="flex gap-4 items-center">
                  <Controller
                    name={`warningField.translations.${locale}.value`}
                    control={control}
                    render={({ field }) => (
                      <InputFormField
                        defaultValue={warningDefaultText(defaultValues)}
                        label={t('common:Warning')}
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                  <span className="pt-3">
                    <WarningIcon color="#FFA500" />
                  </span>
                </div>
              </div>
            </div>
            <div className="w-[30%]">
              <div className="flex items-center justify-between border-b py-4 mb-4">
                <p> {t('Direct')} </p>
                <Controller
                  name={'direct'}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Switch
                        className="direct_btn"
                        sx={switchStyles}
                        value={field.value}
                        defaultChecked={!!field.value}
                        onChange={(event, val) => field.onChange(val)}
                      />
                    );
                  }}
                />
              </div>
              {defaultValues.website ? (
                <div className="flex items-center justify-between border-b py-4 mb-4">
                  <p>
                    {' '}
                    {t('Website')}{' '}
                    {data && data.sourceWebsiteName
                      ? `(${data.sourceWebsiteName})`
                      : ''}
                  </p>
                  <Controller
                    name={'website'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Switch
                          className="website_btn"
                          sx={switchStyles}
                          value={field.value}
                          defaultChecked={!!field.value}
                          onChange={(event, val) => field.onChange(val)}
                          disabled
                        />
                      );
                    }}
                  />
                </div>
              ) : null}
              {defaultValues['sourcePromotionLP'] ? (
                <div className="flex items-center justify-between border-b py-4 mb-4">
                  <p> {t('Promotion_Preview_Page')} </p>
                  <Controller
                    name={'sourcePromotionLP'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Switch
                          className="direct_btn"
                          sx={switchStyles}
                          value={field.value}
                          defaultChecked={!!field.value}
                          onChange={(event, val) => field.onChange(val)}
                          disabled
                        />
                      );
                    }}
                  />
                </div>
              ) : null}
              {defaultValues['sourcePropertyLP'] ? (
                <div className="flex items-center justify-between border-b py-4 mb-4">
                  <p> {t('Property_Preview_Page')} </p>
                  <Controller
                    name={'sourcePropertyLP'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Switch
                          className="direct_btn"
                          sx={switchStyles}
                          value={field.value}
                          defaultChecked={!!field.value}
                          onChange={(event, val) => field.onChange(val)}
                          disabled
                        />
                      );
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </AccordionView>
        <AccordionView
          defaultExpanded
          titleClassName={'text-[24px]'}
          className={'w-full col-start-1 col-end-4'}
          title={t('Qualification')}
        >
          <div className={'grid grid-cols-3 gap-8'}>
            <InputLayout
              className={classNames('', {
                'opacity-50 pointer-events-none': !ownerType,
              })}
              label={t('Owner_qualification')}
            >
              <Controller
                name="ownerQualification"
                control={control}
                render={({ field }) => (
                  <Select
                    disabled={!ownerType}
                    sx={{
                      '& .MuiSelect-select': {
                        textTransform: 'capitalize',
                      },
                    }}
                    className={'h-[56px] owner_qualification_contact'}
                    labelId="level-label"
                    {...field}
                  >
                    {ownerQualificationChoices &&
                      ownerQualificationChoices.map(
                        (item: selectOptionStringT, index: number) => (
                          <MenuItem
                            key={index}
                            value={item?.id}
                            className={`capitalize owner_qualification_${item.id}`}
                          >
                            {item.value}
                          </MenuItem>
                        )
                      )}
                  </Select>
                )}
              />
            </InputLayout>
            <InputLayout
              className={classNames('', {
                'opacity-50 pointer-events-none': !clientType,
              })}
              label={t('Client_qualification')}
            >
              <Controller
                name="clientQualification"
                control={control}
                render={({ field }) => (
                  <Select
                    disabled={!clientType}
                    sx={{
                      '& .MuiSelect-select': {
                        textTransform: 'capitalize',
                      },
                    }}
                    className={'h-[56px] client_qualification_contact'}
                    labelId="level-label"
                    {...field}
                  >
                    {clientQualificationChoices &&
                      clientQualificationChoices.map(
                        (item: selectOptionStringT, index: number) => (
                          <MenuItem
                            key={index}
                            value={item?.id}
                            className={`capitalize client_qualification_${item.id}`}
                          >
                            {item.value}
                          </MenuItem>
                        )
                      )}
                  </Select>
                )}
              />
            </InputLayout>
          </div>
        </AccordionView>
        <AccordionView
          titleClassName={'text-[24px]'}
          defaultExpanded
          className={'w-full col-start-1 col-end-4'}
          title={t('Rating')}
        >
          <div className={'grid grid-cols-3 gap-8 py-8 border-b'}>
            <div>
              <p className={'text-[12px] opacity-70'}>
                {' '}
                {t('Contact_rating')}{' '}
              </p>
            </div>
            <div>
              <Controller
                name="rating"
                control={control}
                render={({ field }) => (
                  <Rating
                    sx={{
                      '& .MuiRating-iconFilled': {
                        color: '#3446B9',
                      },
                    }}
                    icon={<StarIcon color={'#3446B9'} />}
                    emptyIcon={<StarIcon color={'#F5F5F7'} />}
                    defaultValue={field.value || undefined}
                    size="large"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </AccordionView>
        <AccordionView
          titleClassName={'text-[24px]'}
          defaultExpanded
          className={'w-full col-start-1 col-end-4'}
          title={t('Visibility')}
        >
          <div className={'grid grid-cols-3 gap-8'}>
            <InputLayout label={t('Visible_for')}>
              <Controller
                name="visibility"
                control={control}
                render={({ field }) => (
                  <Select
                    sx={{
                      '& .MuiSelect-select': {
                        textTransform: 'capitalize',
                      },
                    }}
                    className={'h-[56px] visible_for_contact'}
                    labelId="level-label"
                    {...field}
                  >
                    {visibilitiesOptions.map(
                      (item: selectOptionStringT, index: number) => (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          className={`capitalize visible_for_${item.id}`}
                        >
                          {item.value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </InputLayout>
          </div>
        </AccordionView>
        <AccordionView
          titleClassName={'text-[24px]'}
          defaultExpanded
          className={'w-full col-start-1 col-end-4'}
          title={t('Hobbies')}
        >
          <div className={'grid grid-cols-3 gap-8'}>
            <InputLayout label={t('Hobbies')}>
              <Controller
                name="hobbies"
                control={control}
                render={({ field }) => (
                  <Select
                    sx={{
                      '& .MuiSelect-select': {
                        textTransform: 'capitalize',
                      },
                    }}
                    className={'h-[56px]'}
                    multiple
                    {...field}
                  >
                    {hobbies?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        className="capitalize"
                      >
                        {item.translations?.[locale]?.name || item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </InputLayout>
          </div>
        </AccordionView>
      </>
    </NoEditModeFormWrapper>
  );
};

export default AdditionalInformationForm;
