import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  MultipagePropsGenerator,
  PropertyWidgetPreviewProps,
} from '../../../../../types/brochureEditor';
import { Dimension, DimensionApi } from '../../../../../types/property';

import useLocale from '../../../../../hooks/useLocale';
import { optionType } from '../../../../../const/propertiesOptions';
import { http } from '../../../../../libs/axios';

import WidgetTitle from '../../widgetTitle';

const PropertyAreasWidget = ({
  multipageProps = {},
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const columns: {
    field: keyof Dimension;
    headerName: string;
    suffix?: string;
    className?: string;
  }[] = [
    {
      field: 'quantity',
      headerName: 'Nb.',
    },
    {
      field: 'spaceType',
      headerName: t('common:Space_type'),
    },
    {
      field: 'habitable',
      headerName: `${t('Living_Area')} ${t('per_unit')}`,
      suffix: 'm²',
      className: 'text-right',
    },
    {
      field: 'floor',
      headerName: t('floor'),
    },
  ];

  const { dimensions, index } = multipageProps as {
    dimensions?: DimensionApi[];
    index: number;
  };
  const { locale } = useLocale();

  return (
    <div className="flex flex-col gap-4">
      <WidgetTitle
        title={t('areas')}
        widgetOptions={widgetOptions}
        pageIndex={index}
      />
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.field}
                className={classNames(
                  'text-[16px] leading-none font-medium p-4 text-left',
                  col.className
                )}
                style={{
                  color: 'var(--brand-color)',
                  visibility: index === 0 ? 'visible' : 'hidden',
                }}
              >
                {col.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dimensions?.map((dimension) => (
            <tr key={dimension.id}>
              {columns.map((col) => {
                const value = dimension[col.field];

                let result;
                if (typeof value === 'object' && value !== null) {
                  const option = value as optionType;
                  result =
                    option.translations?.[locale]?.name || option.keyname || '';
                } else if (typeof value === 'boolean') {
                  result = value ? t('common:yes') : t('common:no');
                } else if (value !== null) {
                  result = String(value);
                }
                return (
                  <td
                    key={col.field}
                    className={classNames(
                      'text-[16px] leading-none font-medium p-4 text-left border-t border-gray-200',
                      col.className
                    )}
                  >
                    {result} {col.suffix}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PropertyAreasWidget;

export const generateProps: MultipagePropsGenerator = async ({
  previewSubject,
}) => {
  let result = null;
  try {
    const { data: dimensions } = await http.get<DimensionApi[]>(
      `/v1/properties/${previewSubject?.id}/dimensions`
    );

    if (dimensions?.length) {
      result = paginate(dimensions, 18).map((page, index) => ({
        dimensions: page,
        index,
      }));
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

function paginate(collection: unknown[], pageSize: number) {
  const pageCount = Math.ceil(collection.length / pageSize);
  const pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(collection.slice(i * pageSize, (i + 1) * pageSize));
  }
  return pages;
}
