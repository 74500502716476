import { useEffect, useMemo, useState } from 'react';

import useLocale from '../../../../hooks/useLocale';

import {
  Page as PageType,
  PageTypes,
  REPEATABLE_AREA,
} from '../../../../types/brochureEditor';

import { useWidgetOptions } from '../../lib/hooks/usePagesEditor';
import { usePreviewSubject } from '../../lib/hooks/usePreviewSubject';
import { pickMultipagePropsGenerator } from '../../lib/pickMultipagePropsGenerator';

import SinglePage from './singlePage';

function Page({
  page,
  isEditMode = false,
  isFirst = false,
}: {
  page?: PageType | null;
  isEditMode?: boolean;
  isFirst?: boolean;
}) {
  const { previewSubject, originalSubject, type } = usePreviewSubject();
  const { locale } = useLocale();
  const [perPageProps, setPerPageProps] = useState<
    Record<string, unknown>[] | null
  >();

  const repeatableWidgetType = page?.widgets?.[REPEATABLE_AREA]?.type;
  const gen = pickMultipagePropsGenerator(repeatableWidgetType);
  const widgetOptions = useWidgetOptions({
    previewSubject,
    page,
  });

  const ctx = useMemo(
    () => ({
      page,
      locale,
      previewSubject,
      originalSubject,
      type,
      widgetOptions,
    }),
    [page, locale, previewSubject, originalSubject, type, widgetOptions]
  );
  useEffect(() => {
    if (gen) {
      //@ts-ignore
      gen(ctx).then(setPerPageProps);
    }
  }, [gen, ctx]);

  if (page?.type === PageTypes.REPEATABLE) {
    if (perPageProps === undefined) {
      // wait until perPageProps are asynchronously resolved
      return null;
    }

    if (perPageProps === null && isEditMode) {
      // if there is no data to display, only in edit mode show a blank page
      return <SinglePage page={page} isFirst={isFirst} multipageProps={{}} />;
    }

    // otherwise, eigher nothing is displayed or as many pages as there are
    return (
      <>
        {perPageProps?.map((props, index) => (
          <SinglePage
            key={index}
            page={page}
            isFirst={isFirst}
            multipageProps={props}
          />
        ))}
      </>
    );
  }

  return <SinglePage page={page} isFirst={isFirst} />;
}

export default Page;
