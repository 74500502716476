type Listener = (data: any) => void;

class MercureService {
  private eventSources: Map<string, EventSource> = new Map();
  private listeners: Map<string, Listener[]> = new Map();
  private static instance: MercureService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): MercureService {
    if (!MercureService.instance) {
      MercureService.instance = new MercureService();
    }
    return MercureService.instance;
  }

  public subscribe(topicUrl: string): void {
    if (!this.eventSources.has(topicUrl)) {
      // Create a new EventSource for each topic
      const eventSource = new EventSource(
        `${
          process.env.REACT_APP_MERCURE_ROOT
        }/.well-known/mercure?topic=${encodeURIComponent(
          `${process.env.REACT_APP_MERCURE_HOST}/${topicUrl}`
        )}`
      );

      eventSource.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        this.notifyListeners(topicUrl, data);
      };

      // Store the EventSource in the map
      this.eventSources.set(topicUrl, eventSource);
    }
  }

  public unsubscribe(topicUrl: string): void {
    const eventSource = this.eventSources.get(topicUrl);
    if (eventSource) {
      eventSource.close();
      this.eventSources.delete(topicUrl);
      this.listeners.delete(topicUrl); // Optionally remove listeners for that topic as well
    }
  }

  public addListener(topicUrl: string, listener: Listener): void {
    if (!this.listeners.has(topicUrl)) {
      this.listeners.set(topicUrl, []);
    }
    this.listeners.get(topicUrl)?.push(listener);
  }

  public removeListener(topicUrl: string, listener: Listener): void {
    const topicListeners = this.listeners.get(topicUrl);
    if (topicListeners) {
      this.listeners.set(
        topicUrl,
        topicListeners.filter((l) => l !== listener)
      );
    }
  }

  private notifyListeners(topicUrl: string, data: any): void {
    const topicListeners = this.listeners.get(topicUrl);
    if (topicListeners) {
      topicListeners.forEach((listener) => listener(data));
    }
  }
}

export default MercureService.getInstance();
