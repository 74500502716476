import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ActionBtnDataGrid from '../../../features/icons/actionBtnDataGrid';
import { GridValidRowModel } from '@mui/x-data-grid';
import GoBackTag from '../../../features/tags/goBackTag';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { useState } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { http } from '../../../libs/axios';
import { Box, Typography } from '@mui/material';
import WithModal, {
  InjectedModalState,
} from '../../../components/modal/widthModal';
import Modal from '../../../components/modal';
import UserForm from '../addNewUserPage/userForm';
import { confirmDelete } from '../../properties/utils';

function SingleUserHeader({ data, ...rest }: { data: GridValidRowModel }) {
  const { t } = useTranslation('usersPage');
  const { userId } = useParams();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const modalState = rest as InjectedModalState;
  const { firstname, lastname, email, phone, isSuperAdmin, roles, users } =
    data;
  const isUserAssistant = roles.includes('ROLE_ASSISTANT');
  const dataToEdit = { firstname, lastname, email, phone, users };

  const apiKey = isUserAssistant
    ? `/users/assistants/${userId}`
    : `/users/${userId}`;

  const deleteSingleUserHandler = async () => {
    try {
      setIsDeleteLoading(true);
      await http.delete(`/users/${userId}`);
      toast.success(t('alerts:user_deleted') as string);
      navigation('/users');
    } catch (e: any) {
      captureException(e);
      if (e.response.status === 466) {
        toast.warning(e.response.data.message as string);
        navigation('/users');
      } else {
        toast.error(t('alerts:user_not_deleted') as string);
      }
    }
  };

  if (!data) return null;

  return (
    <Box className="w-full pb-4 grid grid-cols-2">
      <GoBackTag link="/users" className="col-span-2" />
      <Box className="col-span-1 flex flex-col justify-center">
        <Typography variant="h3" className="mb-2 capitalize">{`${
          data?.firstname
        } ${data?.lastname?.toUpperCase()}`}</Typography>
      </Box>
      <Box className="col-span-1 flex items-center justify-end py-4">
        <Modal
          width={{
            md: 500,
          }}
          title={isUserAssistant ? t('edit_assistant') : t('edit_user')}
          buttonProps={{
            title: t('Edit_info'),
            variant: 'outlined',
            className: 'text-[#1D1D1F]',
            startIcon: <ActionBtnDataGrid />,
          }}
          {...modalState}
        >
          <UserForm
            type="edit"
            role={isUserAssistant ? 'assistant' : 'user'}
            closeModal={modalState.closeModal}
            dataToEdit={dataToEdit}
            apiKey={apiKey}
          />
        </Modal>
        {!isSuperAdmin && (
          <button
            onClick={() => confirmDelete(deleteSingleUserHandler)}
            className="px-4 py-1 rounded border border-gray-300 ml-2 cursor-pointer flex items-center remove_btn"
          >
            {isDeleteLoading ? (
              <ScatterPlotIcon
                fontSize="large"
                className="animate-spin"
                sx={{
                  fill: '#6E6E72',
                  width: 20,
                  height: 20,
                }}
              />
            ) : (
              <DeleteOutlineIcon fontSize="small" sx={{ color: '#F50057' }} />
            )}
            <Box component="span" className="ml-2 text-sm text-[#F50057]">
              {t('Remove')}
            </Box>
          </button>
        )}
      </Box>
    </Box>
  );
}

export default WithModal(SingleUserHeader);
