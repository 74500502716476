import { WidgetOptionValues } from '../../../types/brochureEditor';

function WidgetTitle({
  title: defaultTitle,
  widgetOptions,
  pageIndex = 0,
}: {
  title: string;
  widgetOptions?: WidgetOptionValues;
  pageIndex?: number;
}) {
  const displayTitle = widgetOptions?.displayTitle?.value;
  if (!displayTitle) {
    return null;
  }

  const isRepeatable = widgetOptions && 'repeatTitle' in widgetOptions;

  const repeatTitle = widgetOptions?.repeatTitle?.value;
  if (isRepeatable && !repeatTitle && pageIndex !== 0) {
    return null;
  }

  const title = widgetOptions?.title?.value || defaultTitle;
  return (
    <h2
      className="text-2xl font-semibold leading-7"
      style={{ color: 'var(--brand-color)' }}
    >
      {title}
    </h2>
  );
}

export default WidgetTitle;
