import { Controller } from 'react-hook-form';
import { Box, Button, FormControl } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import InputLayout from '../../../../components/form/components/inputLayout';
import React, { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import classNames from 'classnames';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { useNotification } from '../../../../hooks/useNotification';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import { useAuth } from '../../../../hooks/useAuth';
import DayJs from 'dayjs';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

const NewCommentForm = (props: {
  onCancelClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  contactId: string;
  mutate: any;
  onAfterSubmit: () => void;
  api?: string;
  property?: boolean;
}) => {
  const {
    onCancelClick,
    contactId,
    mutate,
    onAfterSubmit,
    api,
    property = false,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const {
    user: { id },
  } = useAuth();
  const { t } = useTranslation('propertiesPage');
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useProtectedForm({
    defaultValues: { date: DayJs(new Date()), private: false, body: '' },
  });

  const onSubmit = (data: any) => {
    const body = {
      commentBy: id,
      commentDate: data.date,
      commentBody: data.body,
      isPrivate: data.private || false,
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    const apiUrl = api || `contact/${contactId}/comment`;
    const promise: Promise<AxiosResponse> = http.post(apiUrl, body);

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        addNotification(
          t('common:Your_comment_has_been_successfully_added'),
          'success'
        );
        if (mutate) {
          mutate();
        }
        if (!property) {
          updateHistoric(contactId, 'A comment has been added');
        }
        reset();
        onAfterSubmit();
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = t('common:Failed_to_connect_to_server');
        } else {
          message = t('common:Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  return (
    <form
      className={'grid grid-cols-12 relative'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={'col-start-1 col-end-4 pr-8 2xl:pr-16'}>
        <InputLayout className={'property_details'} label={t('Date')}>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, ...restField } }) => (
              <DesktopDatePicker
                disablePast
                format="DD/MM/YYYY"
                onChange={(event) => {
                  // @ts-ignore
                  onChange(new Date(event));
                }}
                {...restField}
              />
            )}
          />
        </InputLayout>
      </div>
      <div className={'col-start-4 col-end-12'}>
        <InputLayout label={t('Comment')}>
          <Controller
            name={'body'}
            control={control}
            defaultValue={''}
            render={({ field: { value, ...restField } }) => (
              <FormControl
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: '#1D1D1F',
                  },
                }}
              >
                <TextareaAutosize
                  minRows={4}
                  maxRows={6}
                  value={value}
                  className={
                    'MuiInputBase-input MuiOutlinedInput-input rounded p-4 comment_area'
                  }
                  {...restField}
                />
              </FormControl>
            )}
          />
        </InputLayout>
        <Controller
          control={control}
          name={'private'}
          render={({ field }) => (
            <label
              className={'cursor-pointer flex items-center ml-[-12px] mt-8'}
            >
              <Checkbox
                className="private_checkbox"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                defaultChecked={!!field.value}
              />
              <span className={'text-[14px]'}>{t('Make_it_private')}</span>
            </label>
          )}
        />
        <div className={classNames('pt-8 flex items-center gap-4 justify-end')}>
          <Button
            onClick={onCancelClick}
            variant="outlined"
            className="flex justify-center items-center cancel_comment_btn"
            sx={{
              height: '42px',
              color: '#F50057',
            }}
          >
            <Box className="flex justify-center items-center" gap="16px">
              <DeleteOutlineIcon fontSize="small" />
              <div className="font-medium">{t('Cancel')}</div>
            </Box>
          </Button>
          <Button
            disabled={!isDirty}
            type="submit"
            variant="outlined"
            className="flex justify-center items-center Publish_comment_btn"
            sx={{
              color: '#3446B9',
              height: '42px',
            }}
          >
            <Box className="flex justify-center items-center" gap="16px">
              <div className="font-medium">{t('Publish')}</div>
              <CheckIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '14px',
                }}
              />
            </Box>
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
    </form>
  );
};

export default NewCommentForm;
