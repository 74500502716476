import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../components/form/selectFormField';
import { Button, FormControl, InputAdornment, Typography } from '@mui/material';
import InputFormField from '../../../../../components/form/inputFormField';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import ReusableDatePicker from '../../../../../components/datePicker/reusableDatePicker';
import { Controller } from 'react-hook-form';
import { TextareaAutosize } from '@mui/base';
import InputLayout from '../../../../../components/form/components/inputLayout';
import RepeatableBlock from '../../../../../components/form/repeateableBlock';
import CloseIcon from '../../../../../features/icons/closeIcon';
import { useAuth } from '../../../../../hooks/useAuth';
import { useFiltersProperties } from '../../../../../hooks/useFiltersProperties';
import { ICompany } from '../../../../../types/hierarchy/legacy';
import { HierarchyNode } from '../../../../../types/hierarchy';
import { findTreeNode } from '../../../../agencies/tree/utils';
import { getBrokersList } from '../generalInformation';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../../utils';
// import { getBrokersList } from "../generalInformation";
// import { useAuth } from "../../../../../hooks/useAuth";

interface Props {
  currency?: number;
  register: any;
  getValues: any;
  resetField: any;
  watch: any;
  control: any;
  name: string;
}

const sellTypeOptions = [
  {
    value: 'Direct sale',
    id: '1',
  },
  {
    value: 'Forward sale',
    id: '2',
  },
  {
    value: 'Sale commitment',
    id: '3',
  },
];

const TransactionDetailsFields = (props: Props) => {
  const { name, watch, resetField, register, getValues, control } = props;
  const {
    isAssistant,
    user: { users },
  } = useAuth();

  const { assistantAgencyHierarchy } = useFiltersProperties(); // address
  const { t } = useTranslation('propertiesPage');
  const { data: basicHierarchy } = useSWR<ICompany>(
    !isAssistant && '/users/hierarchy'
  );
  let brokerDept: HierarchyNode | undefined;

  const hierarchy: HierarchyNode = isAssistant
    ? assistantAgencyHierarchy
    : basicHierarchy;

  if (hierarchy) {
    if (hierarchy.categoryEntity === 'Department') {
      brokerDept = hierarchy;
    } else {
      brokerDept = findTreeNode(hierarchy, watch('department'), 'Department');
    }
  }

  const brokersList = isAssistant
    ? users
      ? users.map(({ id, firstname, lastname }: any) => {
          return {
            id: String(id),
            label: `${firstname} ${lastname}`,
            value: `${firstname} ${lastname}`,
          };
        })
      : []
    : getBrokersList(brokerDept);

  const [defaultCurrencyValue, setDefaultCurrencyValue] =
    useState<selectOptionStringT>();

  const { data: currenciesOptionsApi } =
    useSWR<selectOptionStringT[]>('currencies');

  useEffect(() => {
    if (currenciesOptionsApi && currenciesOptionsApi[0]) {
      setDefaultCurrencyValue(currenciesOptionsApi[0]);
    }
  }, [currenciesOptionsApi]);

  useEffect(() => {
    if (watch(`${name}.sellType`) !== '2') {
      resetField(`${name}.forwardSale`, {
        defaultValue: null,
      });
    }
  }, [watch(`${name}.sellType`)]);

  const currencyChoice = defaultCurrencyValue;

  return (
    <>
      <StepsFieldset
        className="grid grid-cols-3 property_details"
        title={t('Sale_details')}
      >
        <SelectFormField
          className=""
          label={t('Sell_Type')}
          options={sellTypeOptions}
          defaultValue={getValues(`${name}.sellType`)}
          {...register(`${name}.sellType`)}
        />
        <ReusableDatePicker
          label={t('Forward_Sale')}
          control={control}
          name={`${name}.forwardSale`}
          watch={watch}
          disabled={watch(`${name}.sellType`) !== '2'}
        />
        <InputFormField
          type="number"
          label={t('Sale_price_signed')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register(`${name}.salePriceSigned`, {
            valueAsNumber: true,
          })}
        />
        <ReusableDatePicker
          label={t('Signature_Date')}
          control={control}
          name={`${name}.signatureDate`}
          watch={watch}
        />
        <InputLayout
          className={'col-span-2 '}
          label={t('Global_commission_(HT)')}
        >
          <div className={'grid grid-cols-2 gap-10'}>
            <div
              className={classNames('', {
                '': watch(`${name}.globalCommissionPourcentage`) > 0,
              })}
            >
              <InputFormField
                type="number"
                disabled={watch(`${name}.globalCommissionPourcentage`) > 0}
                InputProps={{
                  placeholder: 'Amount',
                  endAdornment: (
                    <InputAdornment className="uppercase" position="start">
                      {currencyChoice?.value}
                    </InputAdornment>
                  ),
                }}
                {...register(`${name}.globalCommissionAmount`, {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div
              className={classNames('', {
                '': watch(`${name}.globalCommissionAmount`) > 0,
              })}
            >
              <InputFormField
                type="number"
                disabled={watch(`${name}.globalCommissionAmount`) > 0}
                InputProps={{
                  placeholder: 'Percentage',
                  endAdornment: (
                    <InputAdornment className="uppercase" position="start">
                      %
                    </InputAdornment>
                  ),
                }}
                {...register(`${name}.globalCommissionPourcentage`, {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
        </InputLayout>
        <ReusableDatePicker
          label={t('Last_Deposit_Date')}
          control={control}
          name={`${name}.lastDepositDate`}
          watch={watch}
        />
        <InputLayout
          className={'col-span-2 '}
          label={t('Agency_commission_(HT)')}
        >
          <div className={'grid grid-cols-2 gap-10'}>
            <div
              className={classNames('', {
                '': watch(`${name}.agencyCommissionPourcentage`) > 0,
              })}
            >
              <InputFormField
                type="number"
                disabled={watch(`${name}.agencyCommissionPourcentage`) > 0}
                InputProps={{
                  placeholder: 'Amount',
                  endAdornment: (
                    <InputAdornment className="uppercase" position="start">
                      {currencyChoice?.value}
                    </InputAdornment>
                  ),
                }}
                {...register(`${name}.agencyCommissionAmount`, {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div
              className={classNames('', {
                '': watch(`${name}.agencyCommissionAmount`) > 0,
              })}
            >
              <InputFormField
                type="number"
                disabled={watch(`${name}.agencyCommissionAmount`) > 0}
                InputProps={{
                  placeholder: 'Percentage',
                  endAdornment: (
                    <InputAdornment className="uppercase" position="start">
                      %
                    </InputAdornment>
                  ),
                }}
                {...register(`${name}.agencyCommissionPourcentage`, {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
        </InputLayout>
        <ReusableDatePicker
          label={t('Signature_Date')}
          control={control}
          name={`${name}.signatureDate`}
          watch={watch}
        />
        <InputFormField
          type="number"
          label={t('Deposit_Amount')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register(`${name}.depositAmount`, {
            valueAsNumber: true,
          })}
        />
        <ReusableDatePicker
          label={t('Sale_Date')}
          control={control}
          name={`${name}.saleDate`}
          watch={watch}
        />
        <ReusableDatePicker
          label={t('Handover_of_keys')}
          control={control}
          name={`${name}.handoverOfKeys`}
          watch={watch}
        />
        <InputFormField
          label={t('Invoice_Number')}
          type="text"
          {...register(`${name}.invoiceNumber`)}
        />
        <InputLayout label={t('Notes')}>
          <Controller
            name={`${name}.notes`}
            control={control}
            defaultValue={''}
            render={({ field: { value, ...restField } }) => (
              <FormControl
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: '#1D1D1F',
                  },
                }}
              >
                <TextareaAutosize
                  minRows={4}
                  maxRows={6}
                  value={value}
                  className={
                    'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                  }
                  {...restField}
                />
              </FormControl>
            )}
          />
        </InputLayout>
      </StepsFieldset>
      <StepsFieldset
        className="flex flex-col !gap-6"
        title={t('Commission_Reparation')}
      >
        <RepeatableBlock
          control={control}
          name={`${name}.propertyCommissionRepartition`}
          addLabel={t('Add_Broker_Commission')}
          appendObj={{ mainBroker: '', amount: 0, percentage: '' }}
          renderField={(field, index, remove) => (
            <div key={field.id}>
              <div className="flex justify-end">
                <Button
                  className="flex justify-center items-center gap-4 "
                  variant="outlined"
                  aria-label="Delete"
                  onClick={() => confirmDelete(() => remove(index))}
                >
                  <CloseIcon color="#F50057" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#F50057',
                      fontWeight: 500,
                    }}
                  >
                    {t('common:Delete')}
                  </Typography>
                </Button>
              </div>
              <div className="flex flex-col gap-4 ">
                <div className="grid grid-cols-3 gap-4">
                  <SelectFormField
                    isRequired
                    label={t('Broker')}
                    options={brokersList}
                    {...register(
                      `${name}.propertyCommissionRepartition.${index}.mainBroker`
                    )}
                    defaultValue={getValues(
                      `${name}.propertyCommissionRepartition.${index}.mainBroker`
                    )}
                  />
                  <div
                    className={classNames('', {
                      'opacity-50':
                        watch(
                          `${name}.propertyCommissionRepartition.${index}.percentage`
                        ) > 0,
                    })}
                  >
                    <InputFormField
                      label={t('Amount')}
                      type="number"
                      {...register(
                        `${name}.propertyCommissionRepartition.${index}.amount`,
                        {
                          valueAsNumber: true,
                        }
                      )}
                      disabled={
                        watch(
                          `${name}.propertyCommissionRepartition.${index}.percentage`
                        ) > 0
                      }
                      required={index !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className="uppercase"
                            position="start"
                          >
                            {currencyChoice?.value}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className={classNames('', {
                      'opacity-50':
                        watch(
                          `${name}.propertyCommissionRepartition.${index}.amount`
                        ) > 0,
                    })}
                  >
                    <InputFormField
                      label={t('Percentage')}
                      type="number"
                      {...register(
                        `${name}.propertyCommissionRepartition.${index}.percentage`,
                        {
                          valueAsNumber: true,
                        }
                      )}
                      disabled={
                        watch(
                          `${name}.propertyCommissionRepartition.${index}.amount`
                        ) > 0
                      }
                      required={index !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className="uppercase"
                            position="start"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
        <RepeatableBlock
          control={control}
          name={`${name}.propertyCommissionRepartitionIntermediate`}
          addLabel={t('Add_Intermediate_Broker_Commission')}
          appendObj={{ intermediateBroker: '', amount: 0, percentage: '' }}
          renderField={(field, index, remove) => (
            <div key={field.id}>
              <div className="flex justify-end">
                <Button
                  className="flex justify-center items-center gap-4 "
                  variant="outlined"
                  aria-label="Delete"
                  onClick={() => confirmDelete(() => remove(index))}
                >
                  <CloseIcon color="#F50057" />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#F50057',
                      fontWeight: 500,
                    }}
                  >
                    {t('common:Delete')}
                  </Typography>
                </Button>
              </div>
              <div className="flex flex-col gap-4 ">
                <div className="grid grid-cols-3 gap-4">
                  <SelectFormField
                    isRequired
                    label={t('Intermediate_Broker')}
                    options={brokersList}
                    {...register(
                      `${name}.propertyCommissionRepartitionIntermediate.${index}.intermediateBroker`
                    )}
                    defaultValue={getValues(
                      `${name}.propertyCommissionRepartitionIntermediate.${index}.intermediateBroker`
                    )}
                  />
                  <div
                    className={classNames('', {
                      'opacity-50': watch(
                        `${name}.propertyCommissionRepartitionIntermediate.${index}.percentage`
                      ).length,
                    })}
                  >
                    <InputFormField
                      label={t('Amount')}
                      type="number"
                      {...register(
                        `${name}.propertyCommissionRepartitionIntermediate.${index}.amount`,
                        {
                          valueAsNumber: true,
                        }
                      )}
                      disabled={
                        watch(
                          `${name}.propertyCommissionRepartitionIntermediate.${index}.percentage`
                        ).length
                      }
                      required={index !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className="uppercase"
                            position="start"
                          >
                            {currencyChoice?.value}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className={classNames('', {
                      'opacity-50':
                        watch(
                          `${name}.propertyCommissionRepartitionIntermediate.${index}.amount`
                        ) > 0,
                    })}
                  >
                    <InputFormField
                      label={t('Percentage')}
                      type="number"
                      {...register(
                        `${name}.propertyCommissionRepartitionIntermediate.${index}.percentage`
                      )}
                      disabled={
                        watch(
                          `${name}.propertyCommissionRepartitionIntermediate.${index}.amount`
                        ) > 0
                      }
                      required={index !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className="uppercase"
                            position="start"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </StepsFieldset>
    </>
  );
};

export default TransactionDetailsFields;
