import { useTranslation } from 'react-i18next';
import InputFormField from '../../../components/form/inputFormField';
import { Controller, useForm } from 'react-hook-form';
import { TextareaAutosize } from '@mui/base';
import { Box, Button, FormControl, Switch, Typography } from '@mui/material';
import InputLayout from '../../../components/form/components/inputLayout';
import CheckIcon from '@mui/icons-material/Check';
import { AxiosResponse } from 'axios';
import { http } from '../../../libs/axios';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../../../hooks/useNotification';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { ISettingEmails } from '../../../types/settings';
import { Locale } from '../../../hooks/useLocale';
import LangSwitcher from '../../../layout/footer/langSwitcher';
import classNames from 'classnames';
import { switchStylesBlue } from '../../properties/stepper/steps/media/rotateImage';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { confirmDelete } from '../../properties/utils';

interface Props {
  staticMode?: boolean;
  createMode?: boolean;
  initialData?: ISettingEmails;
  mutate?: any;
  onClose?: () => void;
}

const initialValues = {
  isActive: true,
  title: '',
  locale: '',
  subject: '',
  text1: '',
  text2: '',
};

export function getDefaultValues(locale: string, data?: ISettingEmails) {
  if (data && data.translations && data.translations[locale]) {
    return {
      isActive: data.isActive,
      title: data.translations[locale]?.title,
      subject: data.translations[locale]?.subject,
      text1: data.translations[locale]?.text1,
      text2: data.translations[locale]?.text2,
    };
  } else {
    return initialValues;
  }
}

const EmailTemplateForm = (props: Props) => {
  const { initialData, staticMode, createMode, mutate, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const [lang, setLang] = useState<Locale>('en');
  const { t } = useTranslation('');
  const defaultValues = getDefaultValues(lang, initialData);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = (data: any) => {
    const translations = initialData
      ? {
          ...initialData.translations,
          [lang]: {
            title: data.title,
            subject: data.subject,
            text1: data.text1,
            text2: data.text2,
            locale: lang,
          },
        }
      : {
          [lang]: {
            title: data.title,
            subject: data.subject,
            text1: data.text1,
            text2: data.text2,
            locale: lang,
          },
        };

    const body = {
      isActive: data.isActive,
      translations,
    };

    submitDataToServer(body);
  };

  const getNotification = () => {
    let notificationKey = '';
    if (staticMode) {
      notificationKey = 'matching:success';
    } else {
      if (createMode) {
        notificationKey = 'matching:template_created';
      } else {
        notificationKey = 'matching:template_updated';
      }
    }

    return notificationKey;
  };

  const deleteSetting = async () => {
    try {
      setIsLoading(true);
      await http.delete(`email_templates/${initialData?.id}`);
      addNotification(t('matching:template_deleted'), 'success');
    } catch (error) {
      addNotification(t('alerts:server_error'), 'error');
    } finally {
      if (mutate) {
        mutate();
      }
    }
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;
    if (staticMode) {
      if (initialData) {
        promise = http.patch(`setting_emails/${initialData?.id}`, body);
      } else {
        promise = http.post(`setting_emails`, body);
      }
    } else {
      if (createMode) {
        promise = http.post(`email_templates`, body);
      } else {
        promise = http.patch(`email_templates/${initialData?.id}`, body);
      }
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        if (mutate) {
          mutate();
        }
        if (onClose) {
          onClose();
        }
        addNotification(t(getNotification()), 'success');
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = t('alerts:server_error');
        } else if (error.request) {
          message = t('alerts:failed_server_error');
        } else {
          message = t('alerts:unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  useEffect(() => {
    reset(getDefaultValues(lang, initialData));
  }, [lang]);

  return (
    <>
      <form
        key={lang}
        onSubmit={handleSubmit(onSubmit)}
        className={'w-full relative flex gap-8 2xl:gap-20 pt-8'}
      >
        <div className={'2xl:min-w-[150px] flex flex-col'}>
          <div>
            <Typography className="text-[#6E6E72] !text-xs">
              {t('common:Language')}
            </Typography>
            <LangSwitcher
              currentLocale={lang}
              setLanguage={setLang}
              changeAppLocale={false}
              className="w-full !mx-0 !h-16"
            />
          </div>
          {!staticMode ? (
            <div className={'flex items-center gap-2 opacity-70'}>
              <Controller
                name={'isActive'}
                control={control}
                render={({ field }) => {
                  return (
                    <Switch
                      sx={switchStylesBlue}
                      value={field.value}
                      defaultChecked={!!field.value}
                      onChange={(event, val) => field.onChange(val)}
                    />
                  );
                }}
              />
              <p
                className={classNames(
                  'text-[12px] 2xl:text-[14px] font-medium'
                )}
              >
                {t('actions:activate')}
              </p>
            </div>
          ) : null}
        </div>
        <div className={'flex-grow flex flex-col gap-4'}>
          {!staticMode ? (
            <InputFormField
              type="text"
              label={t('matching:title')}
              {...register('title')}
              defaultValue={getValues('title')}
            />
          ) : null}
          <InputFormField
            type="text"
            label={t('matching:subject')}
            {...register('subject')}
            defaultValue={getValues('subject')}
          />
          <InputLayout label={t('matching:main-body-text')}>
            <Controller
              name={'text1'}
              control={control}
              defaultValue={getValues('text1')}
              render={({ field: { value, ...restField } }) => (
                <FormControl
                  sx={{
                    border: '1px solid #c4c4c4',
                    borderRadius: '4px',
                    '&:hover': {
                      borderColor: '#1D1D1F',
                    },
                  }}
                >
                  <TextareaAutosize
                    minRows={4}
                    maxRows={6}
                    value={value}
                    className={
                      'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                    }
                    {...restField}
                  />
                </FormControl>
              )}
            />
          </InputLayout>
          <InputLayout label={t('matching:additional-text')}>
            <Controller
              name={'text2'}
              control={control}
              defaultValue={getValues('text2')}
              render={({ field: { value, ...restField } }) => (
                <FormControl
                  sx={{
                    border: '1px solid #c4c4c4',
                    borderRadius: '4px',
                    '&:hover': {
                      borderColor: '#1D1D1F',
                    },
                  }}
                >
                  <TextareaAutosize
                    minRows={4}
                    maxRows={6}
                    value={value}
                    className={
                      'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                    }
                    {...restField}
                  />
                </FormControl>
              )}
            />
          </InputLayout>

          <div className={'col-span-3 flex justify-end gap-4 pt-4'}>
            {!createMode && !staticMode ? (
              <Button
                sx={{
                  minWidth: '120px',
                  border: '1px solid #3446B9',
                  color: '#F50057',
                  height: '40px',
                }}
                onClick={() => confirmDelete(deleteSetting)}
                startIcon={
                  <DeleteOutlineIcon
                    fontSize="small"
                    sx={{ color: '#F50057' }}
                  />
                }
              >
                {t('actions:delete')}
              </Button>
            ) : null}
            <Button
              disabled={createMode ? !(isDirty && watch('title')) : !isDirty}
              type="submit"
              variant="contained"
              disableElevation
              className="flex justify-center items-center"
              sx={{
                background: '#3446B9',
                height: '40px',
              }}
            >
              <Box className="flex justify-center items-center" gap="16px">
                <div className="font-medium">{t('actions:save')}</div>
                <CheckIcon
                  fontSize="small"
                  sx={{
                    width: '16px',
                    height: '14px',
                  }}
                />
              </Box>
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className={'absolute inset-0 z-10'}>
            <SpinnerLoading />
          </div>
        ) : null}
      </form>
      {createMode ? (
        <button
          disabled={isLoading}
          onClick={onClose}
          className={
            'z-10 absolute right-4 top-4 w-[2rem] h-[2rem] rounded flex items-center justify-center bg-white transition-colors duration-500 hover:text-gray-700 text-black'
          }
        >
          <CloseIcon />
        </button>
      ) : null}
    </>
  );
};

export default EmailTemplateForm;
