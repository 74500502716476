import { guaranteeArray } from '../../../helpers/utils/formatters';
import {
  PageTypes,
  Page,
  PageApi,
  PagePayload,
  PreviewSubjectTypes,
  Template,
  TemplateApi,
  TemplatePayload,
  Widget,
  REPEATABLE_AREA,
  WidgetOptionValues,
} from '../../../types/brochureEditor';
import { initialLayoutState } from './hooks/useLayoutEditor';
import { widgetTypes } from './widgetTypes';

export const getPagesWithoutLayoutParts = (pages: Record<string, Page>) => {
  return Object.values(pages).filter(
    (page) => page.type !== PageTypes.HEADER && page.type !== PageTypes.FOOTER
  );
};

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

export function convertTemplateToClient(template: TemplateApi): Template {
  const pagesObject = convertPagesToClient(template.pages);

  const layout = Array.isArray(template.layout)
    ? template.layout[0]
    : template.layout;

  return {
    options: {
      name: template.name || '',
      usedFor: template.usedFor || String(PreviewSubjectTypes.PROPERTY),
      brandColor: template.brand || '',
      departments: template.departments.map((d) => String(d.id)) || [],
    },
    pages: pagesObject,
    overrides: {},
    layout: layout || initialLayoutState,
    properties: template.properties.map((p) => p.id),
    promotions: template.promotions.map((p) => p.id),
  };
}

export function convertTemplateToServer(template: Template): TemplatePayload {
  const pagesArray = Object.values(template.pages);
  const pagesWithWidgetsAsArray = pagesArray.map((page) =>
    convertPageToServer(page)
  );

  return {
    name: template.options.name,
    brand: template.options.brandColor,
    pages: pagesWithWidgetsAsArray,
    layout: template.layout,
    properties: template.properties,
    departments: template.options.departments,
    usedFor: template.options.usedFor,
  };
}

export function convertPagesToClient(pages: PageApi[]): Record<string, Page> {
  const pagesWithWidgetsAsObject = pages.map(convertPageToClient);
  return normalizeArray<Page>(pagesWithWidgetsAsObject);
}

export function normalizeArray<T extends { id: number }>(array: T[] | null) {
  const result: { [key: number]: T } = {};
  if (!array) {
    return result;
  }

  return array.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, result);
}

export function convertPageToClient(page: PageApi): Page {
  const result: Page = {
    id: page.id,
    idOnServer: page.id,
    mockup: page.template || undefined,
    widgets: convertWidgetsToClient(page),
    type: page.type || PageTypes.DEFAULT,
    headerBg: page.headerBg,
    footerBg: page.footerBg,
  };

  if (page.patternPageId) {
    result.id = page.patternPageId;
  }

  return result;
}

export function convertPageToServer(
  page: Page,
  shouldSetPatternPageId = false
): PagePayload {
  const result: PagePayload = {
    patternPageId: null,
    template: page.mockup || null,
    widgets: convertWidgetsToServer(page),
    type: page.type || PageTypes.DEFAULT,
    headerBg: page.headerBg,
    footerBg: page.footerBg,
    shouldSkipGeneratedDate: page.skipGeneratedDate,
  };

  if (page.idOnServer) {
    result.id = page.idOnServer;
  }

  if (shouldSetPatternPageId) {
    result.patternPageId = page.id;
  }

  return result;
}

function convertWidgetsToClient(page: PageApi) {
  const widgets: Record<string, Widget> = {};

  const widgetsArray = guaranteeArray(page.widgets);
  if (page.type === PageTypes.REPEATABLE) {
    if (widgetsArray?.[0]) {
      widgets[REPEATABLE_AREA] = widgetsArray[0];
    }
  } else {
    for (let i = 0; i < widgetsArray.length; i++) {
      const areaCode = alphabet[i] || 'a';
      const widget = widgetsArray[i];
      if (widget) {
        widgets[areaCode] = widget;
      }
    }
  }

  return widgets;
}

function convertWidgetsToServer(page: Page) {
  const widgets: Widget[] = [];

  if (page.type === PageTypes.REPEATABLE) {
    if (page?.widgets?.[REPEATABLE_AREA]) {
      widgets[0] = page.widgets[REPEATABLE_AREA];
    }
  } else if (page.widgets) {
    for (const [areaCode, widget] of Object.entries(page.widgets)) {
      const index = alphabet.indexOf(areaCode);
      if (index >= 0) {
        widgets[index] = widget;
      }
    }
  }

  return widgets;
}

// With time, we may release updates in widget system and end up in situation when we introduced new options
// to a widget, but it was used in some template without these options. This function makes sure such outdated widgets include these new options.
export function standartizeWidgetOptionValues(editedWidget?: Widget | null) {
  if (!editedWidget) {
    return undefined;
  }
  const optionsFromDetails = widgetTypes[editedWidget.type]?.options || {};
  const defaultOptionValues = Object.keys(optionsFromDetails).reduce(
    (acc, key) => {
      const option = optionsFromDetails[key];
      return { ...acc, [key]: { value: option?.defaultValue } };
    },
    {}
  );
  const optionValues = {
    ...defaultOptionValues,
    ...(editedWidget.options || {}),
  } as WidgetOptionValues;
  return { ...editedWidget, options: optionValues };
}
