import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Button, Switch, Typography } from '@mui/material';

import {
  IDataFields,
  ISettings,
  fieldTypes,
  stepTypes,
  visibilityOption,
} from '../../../../../../types/settings';
import CheckedIcon from '../../../../../../features/icons/checkedIcon';
import CloseIcon from '../../../../../../features/icons/closeIcon';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../../components/form/selectFormField';
import InputFormField from '../../../../../../components/form/inputFormField';
import useLocale, { Locale } from '../../../../../../hooks/useLocale';
import {
  categoryOptionType,
  getOptionsByLanguageString,
} from '../../../../../../const/propertiesOptions';
import AttributesOptions from './attributesOptions';
import LangSwitcher from '../../../../../../layout/footer/langSwitcher';
import FilterAgencyMultiselectWithLoadedOptions from '../../../../../../components/form/filterAgencyMultiselectWithLoadedOptions';
import { copyLanguageOfField } from '../utils';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import SpinnerLoading from '../../../../../../features/spinner/spinnerLoading';

type Props = {
  control: Control<ISettings, any>;
  name: string;
  shouldRemoved: boolean;
  isLoading: boolean;
  field: Record<'id', string>;
  watch: UseFormWatch<ISettings>;
  index: number;
  remove: (index?: number | number[] | undefined) => unknown;
  getValues: UseFormGetValues<ISettings>;
  register: UseFormRegister<ISettings>;
  setValue: UseFormSetValue<ISettings>;
  resetField: UseFormResetField<ISettings>;
  handleSubmit: UseFormHandleSubmit<ISettings>;
  locale: Locale;
  setLocale: (newLocale: Locale) => unknown;
};

const CustomAttributesForm = ({
  control,
  resetField,
  watch,
  shouldRemoved,
  isLoading,
  index,
  remove,
  getValues,
  setValue,
  register,
  locale,
  setLocale,
}: Props) => {
  useEffect(() => {
    shouldRemoved && remove();
  }, [shouldRemoved]);
  const { locale: lang } = useLocale();
  const [prevLocale, setPrevLocale] = useState<Locale>(locale);
  const { data: categoriesOptionsApi } =
    useSWR<categoryOptionType[]>('categories');

  const { data: TagsApi } = useSWR<any>('data_field_tags');

  const tagsOptions = getOptionsByLanguageString(TagsApi, locale);
  const categoriesOptions = getOptionsByLanguageString(
    categoriesOptionsApi,
    locale
  );
  const visibilityOptions = getOptionsByLanguageString(
    visibilityOption,
    locale
  );
  const fieldTypesOptions = getOptionsByLanguageString(fieldTypes, locale);
  const stepTypesOptions = getOptionsByLanguageString(stepTypes, locale);
  const { t } = useTranslation('propertiesPage');
  // Get TypesList via category choice
  const [typeOptions, setTypeOptions] = useState<selectOptionStringT[]>([]);
  const currentCategoryValue = watch(`dataFields.${index}.category`);
  useEffect(() => {
    resetField(`dataFields.${index}.propertyTypes`);
    if (currentCategoryValue?.length && categoriesOptionsApi) {
      const categoryId = Number(currentCategoryValue);

      const categorySelected = categoriesOptionsApi.filter(
        (type) => type.id === categoryId
      );

      const selectedTypeOptions = categorySelected[0]?.propertyTypes;
      const selectedTypeOptionsByLanguage = getOptionsByLanguageString(
        selectedTypeOptions,
        locale
      );

      setTypeOptions(selectedTypeOptionsByLanguage);
    }
  }, [categoriesOptionsApi, currentCategoryValue, lang]);

  const dataField = watch(`dataFields.${index}`);
  useEffect(() => {
    if (dataField) {
      copyLanguageOfField(prevLocale, locale, dataField);
    }
  }, [locale, prevLocale, dataField]);

  const getTagValue = (tag: IDataFields['tag']): string | null | undefined => {
    if (typeof tag === 'string' && tag !== '') {
      return tag;
    } else if (tag && typeof tag === 'object') {
      return tag.defaultTagLocale || null;
    }
    return undefined;
  };

  return (
    <>
      <div className={'grid grid-cols-3 gap-4 border-b pt-[28px]'}>
        <div>
          <LangSwitcher
            setPrevLanguage={setPrevLocale}
            currentLocale={locale}
            setLanguage={setLocale}
            changeAppLocale={false}
            className="w-full !mx-0 !h-16"
          />
        </div>
        <div className={'pl-2'}>
          <Controller
            control={control}
            name={`dataFields.${index}.isRequired`}
            render={({ field }) => (
              <label className={'cursor-pointer flex items-center ml-[-12px]'}>
                <Checkbox
                  className="private_checkbox"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  defaultChecked={!!field.value}
                />
                <span className={'text-[14px] opacity-80'}>
                  {' '}
                  {t('Required_field')}{' '}
                </span>
              </label>
            )}
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end pt-4">
        <Button
          className={`${isLoading ? '!bg-gray-200' : '!bg-white'}`}
          variant="outlined"
          disabled={isLoading}
          aria-label="save block"
          type="submit"
        >
          {isLoading ? (
            <SpinnerLoading />
          ) : (
            <div className="flex justify-center items-center gap-4">
              {' '}
              <CheckedIcon color="#3446B9" />
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#3446B9',
                  fontWeight: 500,
                }}
              >
                {' '}
                {+watch(`dataFields.${index}`)
                  ? t('Edit_custom_field')
                  : t('Save_custom_field')}
              </Typography>
            </div>
          )}
        </Button>
        <Button
          className="flex justify-center items-center gap-4"
          variant="outlined"
          aria-label="Remove block"
          onClick={() => {
            remove();
          }}
        >
          <CloseIcon color="#F50057" />
          <Typography
            sx={{
              fontSize: '14px',
              color: '#F50057',
              fontWeight: 500,
            }}
          >
            {t('Delete')}
          </Typography>
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 gap-4">
          <SelectFormField
            label="Tags"
            options={tagsOptions}
            defaultValue={getTagValue(getValues(`dataFields.${index}.tag`))}
            {...register(`dataFields.${index}.tag`)}
          />
          <div className="flex justify-between items-center mt-4">
            <p className="font-[Inter] font-medium">
              {t('common:Publish_on_website')}
            </p>
            <Switch {...register(`dataFields.${index}.publishedOnWebsite`)} />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <InputFormField
            label={t('Custom_field_name')}
            type="text"
            value={getValues(`dataFields.${index}.translations.${locale}.name`)}
            {...register(`dataFields.${index}.translations.${locale}.name`, {
              onChange: (e) => {
                setValue(
                  `dataFields.${index}.translations.${
                    locale === 'en' ? 'fr' : 'en'
                  }.name`,
                  e.target.value
                );
              },
            })}
            isRequired
          />
          <input
            hidden
            value={locale}
            {...register(`dataFields.${index}.translations.${locale}.locale`)}
          />

          <SelectFormField
            isRequired
            label={t('Visibility')}
            options={visibilityOptions}
            defaultValue={
              getValues(`dataFields.${index}.isVisible`) ? 'Visible' : 'Hidden'
            }
            {...register(`dataFields.${index}.isVisible`)}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <InputFormField
            isRequired
            label={t('Custom_field_label')}
            type="text"
            value={getValues(
              `dataFields.${index}.translations.${locale}.label`
            )}
            {...register(`dataFields.${index}.translations.${locale}.label`, {
              onChange: (e) => {
                setValue(
                  `dataFields.${index}.translations.${
                    locale === 'en' ? 'fr' : 'en'
                  }.label`,
                  e.target.value
                );
              },
            })}
            required={index !== 0}
          />
          <SelectFormField
            isRequired
            label={t('Field_type')}
            options={fieldTypesOptions}
            defaultValue={String(getValues(`dataFields.${index}.type`))}
            {...register(`dataFields.${index}.type`)}
          />
          {watch(`dataFields.${index}.type`) === '1' ||
          watch(`dataFields.${index}.type`) === '2' ? (
            <AttributesOptions
              key={locale}
              watch={watch}
              nestIndex={index}
              control={control}
              register={register}
              locale={locale}
              setValue={setValue}
            />
          ) : null}
        </div>
        <div className="grid grid-cols-3 gap-4">
          <FilterAgencyMultiselectWithLoadedOptions
            isRequired
            label={t('Department')}
            name={`dataFields.${index}.departments`}
            value={watch(`dataFields.${index}.departments`) || []}
            setValue={setValue}
            className="w-full !h-12"
            isMultiple={true}
          />
          <SelectFormField
            isRequired
            label={t('Select_step')}
            options={stepTypesOptions}
            defaultValue={String(getValues(`dataFields.${index}.step`))}
            {...register(`dataFields.${index}.step`)}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <SelectFormField
            isRequired
            label={t('Category')}
            options={categoriesOptions}
            defaultValue={getValues(`dataFields.${index}.category`)}
            {...register(`dataFields.${index}.category`)}
          />
          <SelectFormField
            isRequired
            disabled={!watch(`dataFields.${index}.category`)?.length}
            label={t('Property_types')}
            multiple
            options={typeOptions}
            defaultValue={[]}
            {...register(`dataFields.${index}.propertyTypes`)}
          />
        </div>
      </div>
    </>
  );
};

export default CustomAttributesForm;
