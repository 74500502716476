import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';

import './widgets/universal/list-styles.css';

const FontSizeCalibrator = ({
  html,
  minScale = 0.3,
  center = false,
}: {
  html: string;
  minScale?: number;
  center?: boolean;
}) => {
  const [refOuter, { height: heightOuter }] = useMeasure<HTMLDivElement>();
  const [refInner, { height: heightInner }] = useMeasure<HTMLDivElement>();
  const safetyMargin = 0.1;
  const ratio = ((1 + safetyMargin) * heightInner) / heightOuter;

  const [scale, setScale] = useState(1);

  useEffect(() => {
    const precision = 0.2;
    const step = 0.01;
    if (ratio > 1 + precision) {
      setScale((scale) => {
        return Math.max(scale - step, minScale);
      });
    } else if (ratio < 1 - precision) {
      setScale((scale) => {
        return Math.min(scale + step, 1);
      });
    }
  }, [ratio, minScale]);

  const style = { fontSize: `${scale}em` };

  return (
    <>
      <div
        ref={refOuter}
        className={classNames('absolute inset-0', {
          'flex flex-col justify-center': center,
        })}
      >
        <div
          ref={refInner}
          style={style}
          dangerouslySetInnerHTML={{ __html: html }}
          className="grid gap-[0.75em] style-lists"
        ></div>
      </div>
    </>
  );
};

export default FontSizeCalibrator;
