import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import DateRangeField from '../../components/form/dateRangeField';
import AutocompleteSelect from '../../components/form/autoCompleteSelect';
import FilterAgencyMultiselect from '../../components/form/filterAgencyMultiselect';
import { useAuth } from '../../hooks/useAuth';
import { useFiltersProperties } from '../../hooks/useFiltersProperties';
import useSWR from 'swr';
import useLocale from '../../hooks/useLocale';
import { getOptionsByLanguage } from '../../const/propertiesOptions';
import InputLayout from '../../components/form/components/inputLayout';
import SelectFormField from '../../components/form/selectFormField';
import { http } from '../../libs/axios';
import SpinnerLoading from '../../features/spinner/spinnerLoading';
import classNames from 'classnames';

interface Contact {
  id: string;
  name: string;
}

type Props = {
  mainFilterState: any;
  mainFilterStateProperty: any;
  getValues?: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getInputValue: (value: string, label: string) => void;
  getInputValueProperty: (value: string, label: string) => void;
  watch: UseFormWatch<any>;
  control: any;
  clientName: string;
  setClientName: Dispatch<SetStateAction<string>>;
};

const LeadsFilter: FC<Props> = ({
  register,
  watch,
  control,
  clientName,
  setValue,
  setClientName,
  getInputValue,
  mainFilterState,
  mainFilterStateProperty,
  getInputValueProperty,
  getValues,
}) => {
  const { isAssistant } = useAuth();
  const { agencyHierarchy: hierarchy, assistantAgencyHierarchy } =
    useFiltersProperties();
  const [page, setPage] = useState<number>(1);
  const [fullData, setFullData] = useState<Contact[]>([]);
  const [itemsPerPage] = useState<number>(10);
  const [open, setOpen] = useState<boolean>(false);
  const [contactList, setContactList] = useState<any>([]);
  const { locale } = useLocale();
  const { t } = useTranslation('common');
  function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (contactList.length > 0) {
          setPage(page + 1);
          setFullData([...fullData, ...contactList]);
        }

        resolve();
      }, duration);
    });
  }

  useEffect(() => {
    fullData.length == 0 && setFullData(contactList);
  }, [contactList]);

  const fetchData = async (contactSearch?: string) => {
    try {
      const res = await http.get(
        `/contacts_leads?page=${page}&itemsPerPage=${itemsPerPage}${
          contactSearch ? `&contactName=${contactSearch.trim()}` : ''
        }`
      );
      setContactList(
        res?.data?.map((elem: any) => {
          const key = Object.keys(elem)[0];
          return { name: elem[key as any], id: key };
        })
      );
      if (contactSearch) {
        setFullData(
          res?.data?.map((elem: any) => {
            const key = Object.keys(elem)[0];
            return { name: elem[key as any], id: key };
          })
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [open, page]);

  const { data: sources } = useSWR(`/prospects/sources?lang=${locale}`);

  const sourcesList =
    sources && typeof sources === 'object'
      ? Object.entries(sources).map(([key, value], index) => ({
          id: index,
          key: key,
          value: typeof value === 'string' ? value : String(value),
        }))
      : [];

  const { data: status } = useSWR(`/lead_statuses`);
  const statusList = getOptionsByLanguage(status);

  return (
    <form>
      <Box className="grid grid-cols-3 gap-8 mb-4">
        <AutocompleteSelect
          value={watch('propertyReferences')}
          register={register}
          className="w-full search_reference"
          setValue={setValue}
          isMultiple
          onInputChange={(value: string, label: string) =>
            getInputValueProperty(value, label)
          }
          options={
            mainFilterStateProperty['property']?.find(
              (elm: any) => elm.label === 'propertyReferences'
            )?.options || []
          }
          label={t('property_reference')}
          name={'propertyReferences'}
        />

        <AutocompleteSelect
          value={watch('promotionReferences')}
          register={register}
          className="w-full"
          setValue={setValue}
          isMultiple
          onInputChange={(value: string, label: string) =>
            getInputValue(value, label)
          }
          options={
            mainFilterState['promotion']?.find(
              (elm: any) => elm.label === 'promotionReferences'
            )?.options || []
          }
          label={t('promotion_reference')}
          name={'promotionReferences'}
        />

        <FilterAgencyMultiselect
          label={t('Agencies')}
          node={isAssistant ? assistantAgencyHierarchy : hierarchy}
          name="departments"
          value={watch('departments')}
          setValue={setValue}
          className="w-full !h-14 search_agencies"
          isDisabled={isAssistant && !watch('agents')}
        />

        <SelectFormField
          className={classNames('h-14')}
          label={t('status')}
          value={watch('status') || ''}
          options={statusList}
          defaultValue={getValues('status')}
          {...register('status')}
        />

        <InputLayout label={t('source')}>
          <Select
            className={classNames('h-14')}
            value={watch('source') || ''}
            displayEmpty
            {...register('source')}
          >
            {sourcesList?.map(
              (item: { key: string; value: string; id: number }) => (
                <MenuItem key={item.id} value={item.key} className="capitalize">
                  {item.value}
                </MenuItem>
              )
            )}
          </Select>
        </InputLayout>
        <InputLayout label={t('client_name')}>
          <Autocomplete
            inputValue={clientName}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
              setPage(1);
              setFullData([]);
            }}
            getOptionLabel={(fullData: Contact) => fullData.name}
            options={fullData}
            onChange={(e, selectedContact) => {
              setClientName(selectedContact?.name || '');
              setValue('clientName', selectedContact?.name || '');
            }}
            ListboxProps={{
              onScroll: async () => {
                await sleep(1e3);
              },
            }}
            loading={!fullData}
            loadingText={!fullData && <SpinnerLoading />}
            noOptionsText={t('propertiesPage:No_results_found')}
            renderInput={(params) => (
              <TextField
                onChange={(e) => {
                  console.log('params', params);
                  setFullData([]);
                  fetchData(e.target.value);
                  setClientName(e.target.value);
                }}
                {...params}
                label={t('propertiesPage:Choose_Contact')}
              />
            )}
          />
        </InputLayout>
        <InputLayout label={t('date_range')}>
          <DateRangeField
            nameFrom="startDate"
            nameTo="endDate"
            labelFrom={t('From')}
            labelTo={t('To')}
            control={control}
          />
        </InputLayout>
        <div className="flex justify-between items-center">
          <span>{t('Show_leads_related_to_properties')} :</span>
          <div>
            <Switch
              checked={watch('isProperty') || ''}
              {...register('isProperty')}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span>{t('Show_leads_related_to_developments')} :</span>
          <div>
            <Switch
              checked={watch('isPromotion') || ''}
              {...register('isPromotion')}
            />
          </div>
        </div>
      </Box>
    </form>
  );
};

export default LeadsFilter;
