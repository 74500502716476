import { Suspense, lazy } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { IProperty } from '../../../../../types/property';

import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import SelectFormField from '../../../../../components/form/selectFormField';
import SelectWithOptionsFromApi from './selectWithOptionsFromApi';

import { position } from '../../../../../const/propertiesOptions';

const ReusableDatePicker = lazy(
  () => import('../../../../../components/datePicker/reusableDatePicker')
);

function MainDetails() {
  const { register, getValues, control, watch } = useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  return (
    <StepsFieldset
      className="grid grid-cols-3"
      title={t('common:property-details')}
    >
      <InputFormField
        type="number"
        label={t('floor')}
        {...register('floor', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        TextTransform={false}
        label={t('Total_number_of_floors')}
        {...register('floors', {
          valueAsNumber: true,
        })}
      />
      <SelectWithOptionsFromApi
        label={t('orientation')}
        multiple
        endpoint={'/orientations'}
        defaultValue={getValues('orientations')}
        {...register('orientations')}
      />
      <SelectFormField
        label={t('position')}
        options={position.map((pos) => ({
          id: pos.id,
          value: t(pos.value),
        }))}
        defaultValue={getValues('position')}
        {...register('position')}
      />
      <Suspense fallback={<div></div>}>
        <ReusableDatePicker
          sendYear
          views={['year']}
          format="YYYY"
          disablePast={false}
          name="constructionYear"
          control={control}
          label={t('construction_year')}
          watch={watch}
          minDate={dayjs.utc().year(1400)}
          maxDate={dayjs().add(5, 'year')}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <ReusableDatePicker
          sendYear
          views={['year']}
          format="YYYY"
          disablePast={false}
          name="renovationYear"
          control={control}
          label={t('renovation_year')}
          watch={watch}
          minDate={dayjs(watch('constructionYear')) || dayjs.utc().year(1400)}
          maxDate={dayjs().add(1, 'year')}
        />
      </Suspense>
      <SelectWithOptionsFromApi
        label={t('state')}
        endpoint={'/property_states'}
        defaultValue={getValues('state')}
        {...register('state')}
      />
      <SelectWithOptionsFromApi
        label={t('sunlight')}
        endpoint={'/property_sunlights'}
        defaultValue={getValues('sunlight')}
        {...register('sunlight')}
      />
      <SelectWithOptionsFromApi
        label={t('heating_type')}
        endpoint={'/property_heating_types'}
        defaultValue={getValues('heatingType')}
        {...register('heatingType')}
      />
      <SelectWithOptionsFromApi
        label={t('energy')}
        endpoint={'/property_energies'}
        defaultValue={getValues('energy')}
        {...register('energy')}
      />
      <SelectWithOptionsFromApi
        label={t('sound_level')}
        endpoint={'/property_sound_levels'}
        defaultValue={getValues('soundLevel')}
        {...register('soundLevel')}
      />
      <SelectWithOptionsFromApi
        label={t('view')}
        multiple
        endpoint={'/property_views'}
        defaultValue={getValues('views').map(String)}
        {...register('views')}
      />
      <SelectWithOptionsFromApi
        label={t('common:hotWater')}
        endpoint={'/property_hot_waters'}
        defaultValue={getValues('hotWater')}
        {...register('hotWater')}
      />
      <SelectWithOptionsFromApi
        label={t('Glass')}
        endpoint={'/property_glasses'}
        defaultValue={getValues('glass')}
        {...register('glass')}
      />
    </StepsFieldset>
  );
}

export default MainDetails;
