import {
  //   GridColumnHeaderParams,
  GridColumns,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Box, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CreatedUpdatedHeader from '../../tableGridX/createdUpdatedHeader';
import CreatedUpdated from '../../tableGridX/createdUpdated';
import { t } from 'i18next';

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');

export const portalsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'name',
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    headerName: 'Portal',
    renderCell: ({ row }) => {
      return (
        <div>
          <Link
            className={'text-base'}
            to={`/settings/portals/${row.id}/portal-information?lang=${row.language}`}
          >
            {row.portal.name}
          </Link>
        </div>
      );
    },
  },
  {
    field: 'agency',
    maxWidth: 256,
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    cellClassName: '!whitespace-normal',
    headerName: 'Agencies',
    renderCell: ({ row }) => {
      return (
        row?.departments && (
          <div className=" flex gap-2">
            <p className={'font-medium'}>
              {row?.departments.map((elem: { name: string }, index: number) => {
                return (
                  <span key={index}>
                    {elem.name}
                    {index < row.departments.length - 1 && ' , '}
                  </span>
                );
              })}
            </p>
          </div>
        )
      );
    },
  },
  {
    field: 'status',
    maxWidth: 256,
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    headerName: 'Status',
    renderCell: ({ row }) => {
      const [Active, Hidden] = [t('common:Active'), t('common:Hidden')];
      return (
        <div>
          <div
            className={classNames(
              'inline-flex py-0.5 px-2 rounded-md items-center border',
              {
                'border-[#F50057] text-[#F50057]': !row.isActive,
                'border-[#4CAF50] text-[#4CAF50]': row.isActive,
              }
            )}
          >
            <span className="text-[12px] 2xl:text-[16px] leading-5">
              {row.isActive ? Active : Hidden}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    maxWidth: 256,
    minWidth: 200,
    flex: 1,
    headerClassName: '!border-r-0',
    cellClassName: '!border-r-0',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: ({ row }) => (
      <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
    ),
  },
];

export const skeletonPortalsListingConfig: GridColumns<GridValidRowModel> = [
  {
    field: 'name',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={80} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'agency',
    maxWidth: 256,
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    headerName: 'Agency',
    renderCell: () => {
      return (
        <Skeleton variant="text" width={80} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'status',
    maxWidth: 256,
    minWidth: 150,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: () => {
      return (
        <Skeleton variant="text" width={80} height={20} animation="wave" />
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'date',
    maxWidth: 256,
    minWidth: 200,
    flex: 1,
    sortable: false,
    filterable: false,
    headerClassName: '!border-r-0',
    renderHeader: () => <CreatedUpdatedHeader />,
    renderCell: () => {
      return (
        <Box>
          {' '}
          <Skeleton
            variant="text"
            width={isLarge === 'true' ? 250 : isMacLarge === 'true' ? 220 : 200}
            height={20}
            animation="wave"
          />
          <Skeleton
            variant="text"
            width={isLarge === 'true' ? 250 : isMacLarge === 'true' ? 220 : 200}
            height={20}
            animation="wave"
          />
        </Box>
      );
    },
  },
];
