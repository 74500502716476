/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RolesT } from '../../../../types/user';
import HistoryIcon from '../../../../features/icons/historyIcon';
import Modal from '../../../../components/modal';
import PropertiesHistoryListingTable from './components/propertiesHistoryListingTable';
import FilterLayout from '../../../../components/globalFilter/filterLayout/filterLayout';
import { useForm } from 'react-hook-form';
import { initialPropertiesHistoryFilterValues } from '../../../../types/filter';
import { extratagentsFromDept } from '../../../../helpers/utils';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import usePropertyFilterStore from '../../../../hooks/usePropertyFilterStore';
import PropertiesHistoryFilter from './components/propertiesHistoryFilter';
import useListingCollection from '../../../../hooks/useListingCollection';
import { isArray, mapValues, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../hooks/useAuth';
import { collectUsersFromAgencies } from '../../../promotions/stepper/steps/properties/utils';

type Props = {
  id: number | null;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
  action?: string;
};
type DoneBy = {
  agencyName: string;
  avatar: string;
  firstname: string;
  isSuperAdmin: boolean;
  lastname: string;
  roles: RolesT;
};
export type HistoryT = {
  id: number;
  action: string;
  date: string;
  doneBy: DoneBy;
};
const PropertiesHistoryModal = ({
  id,
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  action,
}: Props) => {
  const {
    itemsToDisplay,
    loadingItemsToDisplay,
    resetFilter,
    setPage,
    itemsPerPage,
    page,
    getFiltredProperties,
  } = useListingCollection({
    basedUrl: id ? `v1/properties/${id}/historics` : '',
    currentPage: 'propertiesHistory',
    action: action,
  });
  const { isAssistant, user } = useAuth();
  const { updateElementOptions, mainFilterState } = usePropertyFilterStore({
    basedUrl: `/v1/properties`,
    currentPage: 'propertiesHistory',
  });

  const { t } = useTranslation('propertiesPage');
  const { reset, register, setValue, watch, control, getValues } = useForm({
    defaultValues: initialPropertiesHistoryFilterValues,
  });

  const { agencyHierarchy, agentsPerDept, setAgentOptionsApi } =
    useFiltersProperties();
  useEffect(() => {
    agentsPerDept.length === 0 && setAgentOptionsApi();
  }, [setAgentOptionsApi]);

  useEffect(() => {
    let agentsOptions: any = [];
    // if the user is assistant we should display in the agent field only the agents that he belong to
    if (isAssistant) {
      const { users }: any = user;
      agentsOptions =
        users.length > 0
          ? users.map(({ id, firstname, lastname }: any) => {
              return { id, value: `${firstname} ${lastname}` };
            })
          : [];
    } else {
      if (watch('departments').length > 0) {
        setValue('doneBy', null);
        const agenciesDept = watch('departments');
        agentsOptions = collectUsersFromAgencies(agenciesDept, agencyHierarchy);
      } else {
        if (agentsPerDept) {
          agentsOptions = extratagentsFromDept(agentsPerDept);
        }
      }
    }

    updateElementOptions('doneBy', agentsOptions);
  }, [watch('departments'), agentsPerDept]);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    handleCloseModal();
  };

  const handleResetFilter = () => {
    reset();
    resetFilter();
  };

  // scroll and submit search
  const handleFilterDataPreparation = () => {
    const dataTosend = pickBy(watch(), (value) => {
      return isArray(value)
        ? value.length > 0
        : value !== undefined && value !== null && value !== '';
    });
    const newdataTosend: any = mapValues(dataTosend, (value, key) => {
      if (key === 'doneBy') {
        if (Array.isArray(value)) {
          return value.map((item: any) => Number(item));
        }
        return [Number(value)];
      }
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });
    return newdataTosend;
  };
  const submitSearch = async () => {
    try {
      // Proceed with the rest of the logic
      const newdataTosend: any = await handleFilterDataPreparation();
      //newdataTosend.lang = locale;
      await getFiltredProperties(newdataTosend);
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  const filters = (
    <FilterLayout
      page="propertiesHistory"
      handleResetFilter={handleResetFilter}
      submitSearch={submitSearch}
    >
      <PropertiesHistoryFilter
        register={register}
        setValue={setValue}
        watch={watch}
        control={control}
        getValues={getValues}
        mainFilterState={mainFilterState}
      />
    </FilterLayout>
  );

  return (
    <Modal
      boxClassName="px-14 py-6 !rounded-none"
      CloseIconStyle={{
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
      isOpen={isModalOpen}
      closeModal={handleClose}
      openModal={handleOpen}
      buttonProps={{
        title: ' ',
        className: 'pr-6 text-sm leading-4 text-gray-900',
      }}
      withButton={false}
      closeIconSize="medium"
      title={
        <div className="flex items-center justify-center gap-4">
          <HistoryIcon className="w-6 h-6" />
          <p className="text-2xl">{t('property_history')}</p>
        </div>
      }
      withTitleDivider={false}
      width={{
        md: '80%',
      }}
    >
      {filters}

      <PropertiesHistoryListingTable
        data={itemsToDisplay!}
        itemsPerPage={itemsPerPage}
        handlePage={setPage}
        page={page}
        loadingItemsToDisplay={loadingItemsToDisplay}
      />
    </Modal>
  );
};

export default PropertiesHistoryModal;
